import {
  REALM_API_ID,
  REALM_API_KEY,
  API_URL,
  COMMON_LOGIN_URL,
  COMMON_BILLING_URL,
  STORAGE_URL,
  COMMON_LOGIN_API_URL,
  PINECONE_NAME,
  PINECONE_CLIENT_NAME,
  LIVE_CHAT_API_URL,
} from "./../config/params";
import { db, fb , storageConnection } from "./../config/firebase";
import * as Realm from "realm-web";
// import { data } from "jquery";
// import { data } from "jquery";
import { applyMiddleware } from "@reduxjs/toolkit";
import firebase from "firebase";
import axios from "axios";
// import { createSelectionChange } from "@reactflow/core/dist/esm/utils/changes";
// import { createSelectionChange } from "@reactflow/core/dist/esm/utils/changes";
// import { useDispatch, useSelector } from "react-redux";
// import { setAllowSaveAndTrain } from "../features/globalSave/globalSaveSlice";

const storage = storageConnection;
// import amplitude from 'amplitude-js';
var md5 = require("md5");
//to gain access to firebase db
var addListPC=[]
var delListPC=[]
var recurringGptData={}

// const dispatch  = useDispatch()

export const firebaseAuth = async (client_token, _id, alias) => {
  console.log('insiddde hatbot methods');
  var TOKEN_URL = API_URL + "/getAuthToken/?client_token=";

  return new Promise(async (resolve, reject) => {
    let res = await fetch(
      TOKEN_URL + client_token + "&_id=" + _id + "&alias=" + alias,{
        
      }
    );
    const token = await res.text();
    // localStorage.setItem("fbAuthToken", token);
    console.log(token, "stagingToken");
    
    fb.auth()
      .signInWithCustomToken(token)
      .then((userCredential) => {
        resolve(true);
      })
      .catch((error) => {
        reject(true);
      });
  });
};

//getting cookie
export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      var valueData = c.substring(name.length, c.length);
      return valueData;
    }
  }
  return "";
};


let activeLoaderIds = []

export const startLoader = (startId) => {
    /// console.log(activeLoaderIds,"loader_act")
    let find = false;
    activeLoaderIds.forEach(id => {
        if (id == startId) {
            find = true;
        }
    });
    if (!find) {
        activeLoaderIds.push(startId);
        document.getElementById("global@loader").style.display = "block";
    }


}
export const editLoader = (startId) => {
   
 
        document.getElementById("message").innerText = startId;
    


}
export const stopLoader = (stopId) => {
    // console.log(activeLoaderIds,"loader_act",stopId)
    activeLoaderIds.forEach((l, i) => {
        if (l == stopId) {
            // console.log("remove_loader_act",stopId)

            activeLoaderIds.splice(i, 1);
            console.log(activeLoaderIds,"loader_act")
            if (activeLoaderIds.length == 0) {
                document.getElementById("global@loader").style.display = "none";
            }
        }
        if(stopId=="*"){
            activeLoaderIds=[]
            
                document.getElementById("global@loader").style.display = "none";
            
        }
    });
}

// export const getProper=(answer)=>{
//    // console.log(answer,"beforeproper")
//    answer = answer.replaceAll("çp>", "<p>").replaceAll("ç/p>", "</p>");

//    answer = answer.replaceAll("çspan", "<span").replaceAll("ç/span>", "</span>");
//    answer = answer
//      .replaceAll("ça target", " <a target")
//      .replaceAll("ç/a>", "</a>");
//      answer = answer
//      .replaceAll("çol>", "<ol>")
//      .replaceAll("ç/ol>", "</ol>");
//      answer = answer
//      .replaceAll("çspan", " <span")
//      .replaceAll("ç/span>", "</span>");
//    answer = answer
//      .replaceAll("ça href", " <a href")
//      .replaceAll("ç/a>", "</a>");
//    answer = answer
//      .replaceAll("çstrong>", "<strong>")
//      .replaceAll("ç/strong>", "</strong>");
//    answer = answer.replaceAll("çins>", "<ins>").replaceAll("ç/ins>", "</ins>");
//    answer = answer.replaceAll("çem>", "<em>").replaceAll("ç/em>", "</em>");
//    answer = answer.replaceAll("çli>", "<li>").replaceAll("ç/li>", "</li>");
//    answer = answer.replaceAll("çul>", "<ul>").replaceAll("ç/ul>", "</ul>");
//    answer = answer.replaceAll("çbr>", "<br>").replaceAll("ç/br>", "</br>");
//    // console.log(answer,"afterproper")

//    return answer;
// }


export const getProper = (answer) => {
  // console.log(answer, "beforeproper");

  answer = answer.replace(/çp>/g, "<p>").replace(/ç\/p>/g, "</p>");

  answer = answer.replace(/çspan/g, "<span").replace(/ç\/span>/g, "</span>");
  answer = answer.replace(/ça target/g, " <a target").replace(/ç\/a>/g, "</a>");
  answer = answer.replace(/çol>/g, "<ol>").replace(/ç\/ol>/g, "</ol>");
  answer = answer.replace(/çspan/g, " <span").replace(/ç\/span>/g, "</span>");
  answer = answer.replace(/ça href/g, " <a href").replace(/ç\/a>/g, "</a>");
  answer = answer.replace(/çstrong>/g, "<strong>").replace(/ç\/strong>/g, "</strong>");
  answer = answer.replace(/çins>/g, "<ins>").replace(/ç\/ins>/g, "</ins>");
  answer = answer.replace(/çem>/g, "<em>").replace(/ç\/em>/g, "</em>");
  answer = answer.replace(/çli>/g, "<li>").replace(/ç\/li>/g, "</li>");
  answer = answer.replace(/çul>/g, "<ul>").replace(/ç\/ul>/g, "</ul>");
  answer = answer.replace(/çbr>/g, "<br>").replace(/ç\/br>/g, "</br>");
  // console.log(answer, "afterproper");

  return answer;
};


//getting realm session
export const getRealmSession = async (loginCookie) => {
  
  const credentials = Realm.Credentials.serverApiKey(REALM_API_KEY);
  const app = new Realm.App({ id: REALM_API_ID });
  let user = app.currentUser;
  // try {
    const md5 = require("md5");
    if (!user) {
      user = await app.logIn(credentials);
    }
    //   console.log(getCookie('connect.sid'),"COOKIESSSSSSS",getCookie("APPYID"))
    const fileName = loginCookie;
    console.log(fileName, "connectsid");
    const realmData = await user.functions.getSessionData(
      md5(fileName),
      "owner"
    );
    console.log(realmData, "getrealmsession api responsr");
    if(!realmData){
      return null
    }
    let userData =
      realmData && realmData.userdata ? JSON.parse(realmData.userdata) : "";
    userData = userData && userData.userdata ? userData.userdata : "";
    if(!userData){
     
        return null
      
    }
    if(!userData){
     
        return null
      
    }
    console.log(JSON.stringify(userData), "getrealmsession", userData.email);
    localStorage.setItem("commonlogin_id", userData._id);
    return userData;
  // } catch (error) {
  //   console.error("Error", error);
  //   return null;
  // }
  // } catch (error) {
  //   console.error("Error", error);
  //   return null;
  // }
};



//get real m data from the api
export const getUserData = async (loginCookie) => {

  return new Promise((resolve , reject) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        
    },
      body: JSON.stringify({
        file_name : loginCookie
      }),
      
  };
     fetch(COMMON_LOGIN_API_URL+"/api/common/getuserdetailnew", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result , ' response of getuserdetailnew');
            
            if (result.status === 200) {
                resolve(result.userdata)
            } else {
                resolve(false)
            }
        })
        
        .catch(err => {
          console.log(err , ' error in getuserdetailnew')
          resolve(false)
        });
  })
}



//verify appyid
export const verifyUser = async (loginCookie) => {
  return new Promise((resolve , reject) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        
    },
      body: JSON.stringify({
        file_name : loginCookie
      }),
      
  };
     fetch(COMMON_LOGIN_API_URL+"/api/common/company/verifyUser", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result , ' response of getuserdetailnew');
            
            if (result.status === 200 && result.data) {
                resolve({status:true,data:result.data})
            } else {
              resolve({status:false})
            }
        })
        
        .catch(err => {
          console.log(err , ' error in getuserdetailnew')
          resolve(false)
        });
  })
}

//checking if email exists in firebase db
// export const isEmailExists = async (email, phone, loginAlias) => {
//   console.log("CHECKISEMAILEXISTING", email, phone, loginAlias);

//   var res = false;

//   if (loginAlias) {
//     // 
//     await db
//       .collection("main_user")
//       .where("tablealias", "==", loginAlias)
//       .limit(1)
//       .get()
//       .then((doc) => {
        
//         if (doc.docs.length > 0) {
//           res = { email: true, deactivated: false, id: doc.docs[0].id };
//         } else {
//           res = false;
//         }
//       });

//     return res;
//   } else {
//     await db
//       .collection("main_user")
//       .doc(email ? email.toLowerCase() : phone)
//       .get()
//       .then((doc) => {
//         console.log(doc.exists, "ISEMAILEXISTING");
//         if (doc.exists) {
          

//           res = {
//             email: true,
//             deactivated: false,
//             id: email ? email.toLowerCase() : phone,
//           };
//         } else {
//           res = false;
//         }
//       });

//     return res;
//   }
// };


export const isEmailExists = async (email, phone, loginAlias) => {
  console.log("CHECKISEMAILEXISTING", email, phone, loginAlias);

  var res = false;

  if (loginAlias) {
    // 
    await db
      .collection("main_user")
      .where("tablealias", "==", loginAlias)
      .limit(1)
      .get()
      .then((doc) => {
        
        if (doc.docs.length > 0) {
          localStorage.setItem("tablealias", doc.docs[0].data().tablealias);
  localStorage.setItem("agent_id", doc.docs[0].data().agent_id);
          res = { email: true, deactivated: false, id: doc.docs[0].id };
        } else {
          res = false;
        }
      });

    return res;
  } else {
    await db
      .collection("main_user")
      .doc(email ? email.toLowerCase() : phone)
      .get()
      .then((doc) => {
        console.log(doc.exists, "ISEMAILEXISTING");
        if (doc.exists) {
          console.log(doc.data(),"MAIN_USER_DATA")
          localStorage.setItem("tablealias", doc.data().tablealias);
  localStorage.setItem("agent_id", doc.data().agent_id);
          
          if(localStorage.getItem('tablealias') !== doc.data().tablealias){
            checkAliasInDb(doc.data().tablealias , doc.data().agent_id).then((status) => {
              
             if(status){
              res = {
                email: true,
                deactivated: false,
                id: email ? email.toLowerCase() : phone,
              };
              insertIntoCommonLogin(doc.data().tablealias , doc.data().agent_id)
              localStorage.setItem("tablealias", doc.data().tablealias);
              localStorage.setItem("agent_id", doc.data().agent_id);
             }
             else{
              res = false
             }
            })
           
          }
          else{

          
         
          

          res = {
            email: true,
            deactivated: false,
            id: email ? email.toLowerCase() : phone,
          };}
        } else {
          res = false;
        }
      });

    return res;
  }
};


const checkAliasInDb = (tableAlias , agentId) => {
    return new Promise((resolve , reject) => {
      db.collection('user_' + tableAlias).doc(agentId).get().then((doc) => {
        if(doc.exists){
          resolve(true)
        }
        else{
          resolve(false)
        }
      })
    })
}

const insertIntoCommonLogin=(tableAlias , agentId)=>{
  return new Promise(async (resolve , reject) => {

    //getting the data from the common login

    const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }


    var jsonData = {
      email: userData.email,
      tablealias:tableAlias,
      agent_id: agentId
  };
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Cookie": "appssession=oi2m0i8b08bktm01btri5lqij2"
    },
      body: JSON.stringify(jsonData),
      redirect: 'follow'
  };
     fetch(COMMON_LOGIN_API_URL+"/api/users/insertchatbot", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result);
            
            if (result.status_msg === 200) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
        
        .catch(err => console.log(err));
  
  })


}





//redirect to common login page
export const redirectToCommonLogin = async () => {
  let hostDomain = window.location.hostname;
  let htp = "https";


    if ( sessionStorage.getItem("toSignUp")) {
      sessionStorage.removeItem("toSignUp");
      window.location.href =
        COMMON_LOGIN_URL +
        "/register?frompage=" +
        htp +
        "://" +
        hostDomain +
        "/login%2F&website=" +
        htp +
        "://" +
        hostDomain +
        "/login";
    } else {
      window.location.href =
        COMMON_LOGIN_URL +
        "/login?frompage=" +
        htp +
        "://" +
        hostDomain +
        "/login%2F&website=" +
        htp +
        "://" +
        hostDomain +
        "/login";
    }
  
};

//register user if does not exist
export const handleLogin = async (userData, navigate) => {
  
  return new Promise (async (resolve,reject)=>{
  var userdata = userData;
  var email = userdata.email ? userdata.email : "";
  var phone = userdata.user_mobile ? userdata.user_mobile : "";
  var mobile_number = userdata.mobile_number ? userdata.mobile_number : "";
  var loginAlias = userdata.table_alias ? userdata.table_alias : "";
  var id = userdata._id ? userdata._id : "";
  var access = userdata.access_token;
  var company_name = userdata.company;
  var tablealias = userdata.table_alias;
  var owner_id = userdata.owner_id;

  console.log(userdata, "udata", navigate);
  localStorage.setItem("accessToken", access);
  localStorage.setItem("cognito_id", id);
  localStorage.setItem("isLogin", true);
  // localStorage.setItem("email", email ? email : "");
  // localStorage.setItem("phone", phone ? phone : "");
  // localStorage.setItem("mobile_number", mobile_number);
  localStorage.setItem("company_name", company_name);



  localStorage.setItem("tablealias", tablealias);
  localStorage.setItem("agent_id", owner_id);


    

  await firebaseAuth(access, id, "freee");

  
  let apps_access = userdata.apps_access ? userdata.apps_access : [];
  let isAccess = false;
  apps_access.forEach((app) => {
    if (app.product == "chatbot") {
      isAccess = true;
    }
  });
  if (!isAccess) {
    registerUserInCommonLogin(userdata._id);
  }

  
      await getOrCreateUserCollection(
        email,
        phone,
        mobile_number,
        id,
        access,
        userdata
      )


      //setting the session in the db
      //setting the force logout to false
      // setForceLogout().then(res => {
      //   if(res){
      //     console.log('force logout set to false');
      //   }
      //   else {
      //     console.log(' unable to set force logout');
      //   }
      // })
      resolve()
    
  
})
};


//setting the force logout to false in the db
const setForceLogout = async () => {

  return new Promise((resolve , reject) => {
    
    db.collection("user_"+localStorage.getItem("tablealias")).doc(localStorage.getItem("agent_id")).update({forceLogout:false,forceLogoutTime:new Date().getTime()})
    .then(()=>{
      
      console.log('Successfully updated the force logout to false');
      resolve(true)
  
    })
    .catch(err=>{
      console.log(err , '  error in force logout');
      console.log('unable to set force logout to false');
      resolve(false)
    })
  })
  
}

export const registerUserInCommonLogin = async (_id) => {
  let alias = localStorage.getItem("tablealias");
  let agent_id = localStorage.getItem("agent_id");
  var body = [`grant_type=password&username=pedroetb&password=password`];
  
  var a = await new Promise((resolve, rej) => {
    fetch(COMMON_LOGIN_API_URL + "/oauth/token", {
      method: "post",
      body: body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic YXBwbGljYXRpb246c2VjcmV0`,
      },
    })
      .then((e) => e.json())
      .then(async (response) => {
        resolve(response.accessToken);
      })
      .catch((e) => {
        resolve("e26dda5b4c78903ed190f840e958484482411714");
      });
  });
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("user_id", _id);
  urlencoded.append("table_alias", alias);
  urlencoded.append("owner_id", agent_id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  try {
    let r = await fetch(
      COMMON_LOGIN_API_URL + "/api/common/savechatbot?access_token=" + a,
      requestOptions
    );
    r = await r.text();
    console.log(r, "testdheeraj123");
    return true;
  } catch (error) {
    return false;
  }
};

export const getOrCreateUserCollection = async (
  email,
  phone,
  mobile_number,
  password,
  token,
  userData
) => {
  console.log(
    email,
    phone,
    mobile_number,
    password,
    token,
    userData,
    "userDatauserDatauserDatauserDatauserData"
  );

  var response = "";

 
  let apps_access = userData.apps_access;
  let userId = userData._id;
  let accessAppp = [];
  apps_access.forEach((element) => {
    accessAppp.push(element.product);
  });

  var isEmail = await isEmailExists(email, phone);
  // localStorage.setItem("temproryToken", token);
  console.log(isEmail, "isEmail");
  if (isEmail.email) {  
    sessionStorage.setItem("newSession", true); 
    
    //LOGGING THE ACTIVITY
    //LOGIN ACTIVITY
    const activityData = {
      login : {
          email : isEmail.email
      }
    }
    trackData('LOGIN_SUCCESSFULL' , 'Login' , 'User has been logged in' , activityData).then(res => {
        if(res){
          console.log('LOGIN_SUCCESSFULL activity log')
        }
    })

  } else {
    response = await createNewUserInDb(
      userData,
      email,
      phone,
      mobile_number,
      password,
      token,
      accessAppp
    );
     
     //LOGGING THE ACTIVITY
    // SIGNUP ACTIVITY
    const activityData = {
      signup : {
          email : isEmail.email
      }
    }
    trackData('SIGNUP_SUCCESSFULL' , 'Signup' , 'User has been registered' , activityData).then(res => {
        if(res){
          console.log('SIGNUP_SUCCESSFULL activity log')
        }
    })
    
    if(!sessionStorage.getItem("botName")||!sessionStorage.getItem("botType")){

      if(!sessionStorage.getItem("loginSource")){
        sessionStorage.setItem('loginSource','/builder')
      }
    }
   

  }
  setCookieForConnect()
  await makeMoreAppt()
 

  return response;
};

export const makeMoreAppt= async ()=>{
  var body = [
    `tablealias=${localStorage.getItem("tablealias")}`,
  ];
  await fetch(API_URL + "/appointments/makeSlots", {
    method: "post",
    body: body,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
}

export const createNewUserInDb = async (
  userData,
  email,
  phone,
  mobile_number,
  password,
  token,
  apps_access
) => {


  var tableAlias = userData.table_alias;
  var agentId = userData.owner_id;
  console.log(tableAlias, agentId,apps_access,"apps_accessapps_access");
  let respo = "";
  const id = tableAlias;

  const addedon = new Date().getTime();
  const agent_id = agentId;
  // const agent_id=userData.owner_id;

  

  var botName = localStorage.getItem("BotName")
    ? localStorage.getItem("BotName")
    : "Inquiry";

  var _name = email ? email.substring(0, email.lastIndexOf("@")) : "";
  if (userData !== undefined) {
    if (userData.first_name !== "") {
      _name = userData.first_name + " " + userData.last_name;
    }
  }
  var _password = md5(password);

  var country = "";

  country = userData.country ? userData.country : "";

  var main_user_data = {
    email: email ? email : "",
    phone: phone ? phone : "",
    mobile_number: mobile_number ? mobile_number : "",
    agent_company: id,
    agent_parent: null,
    password: "_password",
    agent_id: agent_id,
    tablealias: id,
    isAdmin: true,
    date: new Date(),
    session: token,
    bigquery_implemented: true,
    country: country,
    isFromApp:apps_access
  };
  respo = await addNewUser(email, phone, main_user_data);

  
  sessionStorage.setItem("newUser", true);


  document.cookie = `firstFlow=true;  path=/;`;
  var payment_data = {};
  // profile data
  var profile_data = {
    id: id,
    addedon: addedon,
    agent_id: agent_id,
    name: _name,
    alias: _name,
    agent_short: nameIntials(_name),
    profile_img: "",
    email: email,
    phone: phone,
    departments: ["Owner"],
    role: "Owner",
    role_id: "Owner",
    password: _password,
    timezone: "",
    parent_id: agent_id,
    logout: false,
  };

  // setting data
  var setting_data = {
    chatbot_setting: {
      idle_time: {
        A_end_for_both_VA: false,
        A_inactive: false,
        A_inactive_time: "10",
        A_show: false,
        A_show_text: "",
        A_status_change_aftertime: 0,
        A_time: 10,
        V_end_for_both_VA: false,
        V_remove: false,
        V_time: 10,
        agent_not_reply: false,
        visitor_not_reply: false,
        A_inactive_status: 0,
      },
      localization: {
        language: "English :en",
        timezone: "Pacific/Apia",
      },
      notification_sound: {
        incoming_chat_play: false,
        incoming_chat_show: false,
        new_msg_received_play: false,
        new_msg_received_show: false,
      },
      pervious_show: false,
    },
    livechat_setting: {
      GDPR: {
        ask_consent: 0,
        consent_button_text: "Yes,I agree",
        consent_content:
          "I agree for my personal data, provided via chat, to be processed by <your company name> for the purpose of providing support via chat. I agree for my personal data to be processed for the time <e.g. needed to carry out the service>. I understand the consent may be revoked by sending an email at <your business email/your data protection officers email>.",
        content_asked_by: "",
        privacy_policy_link: "",
        privacy_policy_link_text: "",
      },
    },
  };
  // integration data
  var integration_data = {
    all_integration: {
      appy_email: {
        description: "Manage your emails",
        inputs: ["Email id"],
        learnMore: "",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
        title: "Appy Email",
        type: "email",
      },
      appy_appointment: {
        description: "Manage your appointments",
        inputs: ["Email id"],
        learnMore: "",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
        title: "Appy Appointment",
        type: "appointments",
      },
      appy_agentTransfer: {
        description: "Manage all live agent transfers in Appy Pie Livechat",
        inputs: ["Email id"],
        learnMore: "",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
        title: "Appy Livechat",
        type: "agent transfer",
      },
      zendesk: {
        description: "Manage all live agent transfers in zendesk chat",
        inputs: ["Account key"],
        learnMore: "https://api.zopim.com/web-sdk/#initializing-the-sdk",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/Group%202339.svg?alt=media&token=2d50fd44-fe9d-4079-bdef-785fece6a667",
        title: "Zendesk Chat",
        type: "agent transfer",
      },
      smtp: {
        description: "Manage your emails via your SMTP",
        inputs: [
          "Host *",
          "Port *",
          "Email/UserName *",
          "Password *",
          "Sender Name",
          "Sender email",
        ],
        learnMore: "https://www.smtp.com/smtp-email-relay-solutions/",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/Asset-2-smtp-mon.svg?alt=media&token=b4c24d7c-58c5-4bab-a619-ba63be222f4f",
        title: "SMTP",
        type: "email",
      },
      zoom: {
        description: "Manage all live agent transfers in zoom",
        inputs: ["Client id ", "Client secret "],
        learnMore: "https://support.zoom.us/hc/en-us",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/zoom-1.svg?alt=media&token=c3611cdd-0014-44d6-833f-c84a5b05375a",
        title: "ZOOM",
        type: "agent transfer",
      },
      calendarhero: {
        description: "Manage all live agent transfers in Calander Hero",
        inputs: ["Client id ", "Client secret "],
        learnMore: "https://calendarhero.com/",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/zoom-1.svg?alt=media&token=c3611cdd-0014-44d6-833f-c84a5b05375a",
        title: "Calendar Hero",
        type: "agent transfer",
      },
    },
    installed_integration: {
      appy_email: {
        botId: [],
        data: { email: email },
        title: "Appy Email",
        type: "email",
      },
      appy_appointment: {
        botId: [],
        data: { email: email },
        title: "Appy Appointment",
        type: "appointments",
      },
      appy_agentTransfer: {
        botId: [],
        data: { email: email },
        title: "Appy Livechat",
        type: "agent transfer",
      },
    },
  };

  // role data
  var role_data = {
    Owner: {
      access_module: [],
      description: "",
      name: "Owner",
      role: "Owner",
      status: 1,
      updatedon: addedon,
      permissions: {
        usermanagement: {
          agentAccess: true,
          rolesAccess: true,
          departmentAccess: true,
        },
        chatbot: {
          create: true,
          delete: true,
          status: true,
          edit: true,
        },
        livechat: {
          initiateChat: true,
          viewPastchat: true,
          answereIncoming: true,
          viewVisitorPath: true,
        },
        appointment: {
          appointment: true,
          addAgent: true,
          ownRooster: true,
          otherRooster: true,
        },
        others: {
          analytics: true,
          intigeration: true,
          settings: true,
          status_change: true,
          mange_preference: true,
          manage_profile: true,
        },
      },
    },
    Agent: {
      access_module: [],
      description: "",
      name: "Agent",
      role: "Agent",
      status: 1,
      updatedon: addedon,
      permissions: {
        usermanagement: {
          agentAccess: false,
          rolesAccess: false,
          departmentAccess: false,
        },
        chatbot: {
          create: false,
          delete: false,
          status: false,
          edit: false,
        },
        livechat: {
          initiateChat: true,
          viewPastchat: true,
          answereIncoming: true,
          viewVisitorPath: true,
        },
        appointment: {
          appointment: false,
          addAgent: false,
          ownRooster: false,
          otherRooster: false,
        },
        others: {
          analytics: true,
          intigeration: false,
          settings: false,
          status_change: true,
          mange_preference: true,
          manage_profile: true,
        },
      },
    },
  };

  // appointment data
   var appointment_data = {};


  var new_user_data = {
    profile: profile_data,
    payment: payment_data,
    integration: integration_data,
    settings: setting_data,
    role: role_data,
    appointment: appointment_data,
  };
  await create_user_collection(id, agent_id, new_user_data);
  createBigQueryDB(id);
  return respo;
};

const addNewUser = async (email, phone, data) => {
  var res = false;
  await db
    .collection("main_user")
    .doc(email ? email.toLowerCase() : phone)
    .set(data)
    .then(() => {
      res = "new_user";
      return res;

    })
    .catch((error) => {
      console.error("Error on adding main_user: ", error);
      res = false;
      return res;
    });
  // return res;
};

// create new user collection
const create_user_collection = async (tablealias, agent_id, data) => {
  console.log(tablealias, agent_id, data);
  await db.collection("user_" + tablealias)
    .doc(agent_id)
    .set(data);
  await db.collection("user_" + tablealias)
    .doc(agent_id)
    .collection("chatbot")
    .doc("dashboard")
    .set({});
};

// create new user Big query DB
const createBigQueryDB = (tablealias) => {
  //API by SHUBHAM
  var body = [
    `tablealias=${tablealias}&tabletype=feedback_log&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-01-01"&graphtype=2`,
  ];
  fetch(API_URL + "/fetch", {
    method: "post",
    body: body,
    headers: { "Content-Type": "application/x-www-form-urlencoded",  "Authorization": "Bearer "+getCookie("APPYID") },
  });
};

//Making name in Two UpperCase letters
const nameIntials = (nameData) => {
  var arrayData = nameData.split(" ");
  switch (arrayData.length) {
    case 1:
      return nameData.substr(0, 2).toUpperCase();
      break;
    default:
      return (
        arrayData[0].charAt(0).toUpperCase() +
        arrayData[arrayData.length - 1].charAt(0).toUpperCase()
      );
  }
};

//creating new bot or overwrite old bot from the api
export const createBot = (botName, botType , steps = {}) => {
  
  return new Promise(async (resolve, reject) => {



    const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }

    var body = {
      email: userData.email,
      tablealias: localStorage.getItem("tablealias"),
      agentId: localStorage.getItem("agent_id"),
      botId: localStorage.getItem("botId"),
      botName,
      botType,
      steps,
      isNew: true,
    };
    // fetch("http://localhost:5905/chatbot-production-d6ea3/us-central1/makeUser/newBot",{
    fetch(API_URL + "/makeUser/newBot", {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+getCookie("APPYID")
      },
    })
    .then((res) => {
      console.log(res,"STATUS",res.status)
      if(res.status==200){
        resolve(true);

      }else{
        resolve(false);
      }

     
    });

    


  });
};

// export const createBotNew = async (botName, botType) => {  


 
  
//     var body = {
//       email: localStorage.getItem("email"),
//       tablealias: localStorage.getItem("tablealias"),
//       agentId: localStorage.getItem("agent_id"),
//       botId: localStorage.getItem("botId"),
//       botName,
//       botType,
//     };

    // const res = await fetch(API_URL + "/makeUser/newBot", {
    //   method: "post",
    //   body: JSON.stringify(body),
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": "Bearer "+getCookie("APPYID")
    //   },
    // })
    
//       if(res.status==200){
//         return true

//       }else{
//         throw new Error('Bot not created')
//       }
// };

export const getDeployedTelegramList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().telegramLinked != undefined) {
            resolve(data.data().telegramLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        console.error("Error on getting telegram list: ", error);
        resolve([]);
      });
  });
};

export const getDeployedLineList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().lineLinked != undefined) {
            resolve(data.data().lineLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};
export const getDeployedWhatsappList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().whatsappLinked != undefined) {
            resolve(data.data().whatsappLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};
export const getDeployedViberList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().viberLinked != undefined) {
            resolve(data.data().viberLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};

export const whatsappsetup = async (whatsappData, action) => {
  console.log(
    "whatsappsetup----->>><<<>>>-------------------------------------------------->>",
    whatsappData,
    action
  );
  return new Promise(async (resolve, reject) => {
    var botId = whatsappData.BOTID;
    var botAlias = whatsappData.whatsappAccessId + "";
    if (action == "delete") {
      db.collection("Whatsapp_Integrations")
        .doc(botAlias)
        .delete()
        .then(() => {
          console.log("delete");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      db.collection("Whatsapp_Integrations").doc(botAlias).set(whatsappData);
    }

    let userData = await db
      .collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get();
    console.log(userData, "--->>u--s--e--r--d--a--t--a---");
    if (userData.exists) {
      console.log("--->userDta exist-->>");
      let counter = 0;
      let botarray = [];
      let whatsappDatalist = userData.data().whatsappLinked
        ? userData.data().whatsappLinked
        : [];
      console.log(whatsappDatalist, "--->>>>whatsappDatalist-----");
      whatsappDatalist.forEach((bot) => {
        if (bot.docId == whatsappData.docId) {
          ++counter;

          if (action == "setup") {
            botarray.push(whatsappData);
          } else if (action == "disable") {
            whatsappData.isActive = false;
            botarray.push(whatsappData);
          } else if (action == "enable") {
            whatsappData.isActive = true;
            botarray.push(whatsappData);
          }
        } else {
          botarray.push(bot);
        }
      });
      if (counter == 0) {
        botarray.push(whatsappData);
      }
      console.log(
        botarray,
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
      );
      await db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(botId)
        .update({ whatsappLinked: botarray });
    } else {
      alert(1);
    }

    // else if (action == "enable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: true })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(botId)
    //     .update({ "viberLinked.isActive": true })

    // } else if (action == "disable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: false })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked.isActive": false })
    // }
    // else if (action == "delete") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).delete()
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked": {} })
    // }

    resolve(true);
  });
};

export const whatsappLiveChatSetup = async (whatsappData)=>{
  return new Promise ((resolve,reject)=>{

    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(whatsappData),
      redirect:"follow"
    }
    fetch(LIVE_CHAT_API_URL+"whatsapp/integrate",requestOptions)
    .then(res=>res.json())
    .then(result=>resolve(result))
  })
}

export const Linesetup = async (lineData, action) => {
  return new Promise(async (resolve, reject) => {
    if (action == "delete") {
      db.collection("Line_Integrations").doc(lineData.docId).delete();
    } else {
      db.collection("Line_Integrations").doc(lineData.docId).set(lineData);
    }
    let userData = await db
      .collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(lineData.BOTID)
      .get();
    if (userData.exists) {
      let counter = 0;
      let botarray = [];
      let lineDatalist = userData.data().lineLinked
        ? userData.data().lineLinked
        : [];
      lineDatalist.forEach((bot) => {
        if (bot.docId == lineData.docId) {
          ++counter;

          if (action == "setup") {
            botarray.push(lineData);
          } else if (action == "disable") {
            lineData.isActive = false;
            botarray.push(lineData);
          } else if (action == "enable") {
            lineData.isActive = true;
            botarray.push(lineData);
          }
        } else {
          botarray.push(bot);
        }
      });
      if (counter == 0) {
        botarray.push(lineData);
      }
      console.log(
        botarray,
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
      );
      await db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(lineData.BOTID)
        .update({ lineLinked: botarray });
    } else {
      alert(1);
    }

    // else if (action == "enable") {
    //   firebase.collection('Line_Integrations').doc(lineData.docId).update({ isActive: true })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(lineData.BOTID)
    //     .update({ "lineLinked.isActive": true })

    // } else if (action == "disable") {
    //   firebase.collection('Line_Integrations').doc(lineData.docId).update({ isActive: false })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(lineData.BOTID)
    //     .update({ "lineLinked.isActive": false })
    // }
    // else if (action == "delete") {
    //   firebase.collection('Line_Integrations').doc(lineData.docId).delete()
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(lineData.BOTID)
    //     .update({ "lineLinked": {} })
    // }

    resolve(true);
  });
};

export const vibersetup = async (viberData, action, botId) => {
  console.log(
    "vibresetup----->>><<<>>>-------------------------------------------------->>"
  );
  return new Promise(async (resolve, reject) => {
    if (action == "delete") {
      db.collection("Viber_Integrations").doc(botId).delete();
    } else {
      db.collection("Viber_Integrations").doc(botId).set(viberData);
    }
    let userData = await db
      .collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get();
    console.log(userData, "--->>u--s--e--r--d--a--t--a---");
    if (userData.exists) {
      console.log("--->userDta exist-->>");
      let counter = 0;
      let botarray = [];
      let viberDatalist = userData.data().viberLinked
        ? userData.data().viberLinked
        : [];
      console.log(viberDatalist, "--->>>>viberDatalist-----");
      viberDatalist.forEach((bot) => {
        if (bot.docId == viberData.docId) {
          ++counter;

          if (action == "setup") {
            botarray.push(viberData);
          } else if (action == "disable") {
            viberData.isActive = false;
            botarray.push(viberData);
          } else if (action == "enable") {
            viberData.isActive = true;
            botarray.push(viberData);
          }
        } else {
          botarray.push(bot);
        }
      });
      if (counter == 0) {
        botarray.push(viberData);
      }
      console.log(
        botarray,
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
      );
      await db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(botId)
        .update({ viberLinked: botarray });
    } else {
      alert(1);
    }

    // else if (action == "enable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: true })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(botId)
    //     .update({ "viberLinked.isActive": true })

    // } else if (action == "disable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: false })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked.isActive": false })
    // }
    // else if (action == "delete") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).delete()
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked": {} })
    // }

    resolve(true);
  });
};

export const getAllFacebookPage = () => {
  return new Promise(async (resolve, reject) => {
    await db
      .collection("Bot_Deployment")
      .doc("facebook")
      .collection("connected_pages")
      .doc(localStorage.getItem("tablealias"))
      .get()
      .then((doc) => {
        if (doc.data()) {
          let fbPagesLinked = doc.data().pages_linked;
          if (fbPagesLinked && fbPagesLinked.length > 0) {
            resolve(fbPagesLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};

export const getAllConnectedFacebookPage = () => {
  return new Promise(async (resolve, reject) => {
    await db
      .collection(`user_${localStorage.getItem("tablealias")}`)
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(window.location.href.split("?")[1].split("=")[1])
      .get()
      .then((doc) => {
        if(doc.exists){
          let fbPagesLinked = doc.data().facebookPagesLinked;
          resolve(fbPagesLinked ? fbPagesLinked : []);
        }
        else{
          resolve([]);
        }
       
      });
  });
};

export const setHandoffinDBConnect = (data, name, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    console.log("set_Handoff_in_DB");
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(name)
      .set(data)
      .then((doc) => {
        console.log("doc is set");



        if (addGlobal) {
          db.collection("Connect_List")
            .doc(localStorage.getItem("tablealias"))
            .collection("chatbot")
            .doc(name)
            .get()
            .then((doc) => {
              console.log(doc , 'doc is set')
              if (doc.exists) {
                var newData = {}
                var accArr=doc.data()["accounts"]?doc.data()["accounts"]:[];
                accArr.push(data);
                newData["accounts"]=accArr
                db.collection("Connect_List")
                  .doc(localStorage.getItem("tablealias"))
                  .collection("chatbot")
                  .doc(name)
                  .update(newData).then((doc) => {
                    console.log(doc , 'doc is set')
                  }).catch((err) => {
                    console.log(err , 'ljwdfjkew');
                  })
              } else {
                var newData = {}
                var accArr=[data];
                newData["accounts"]=accArr
                db.collection("Connect_List")
                  .doc(localStorage.getItem("tablealias"))
                  .collection("chatbot")
                  .doc(name)
                  .set(newData).then((doc) => {
                    console.log(doc , 'doc is set')
                  }
                  ).catch((err) => {
                    console.log(err , 'ljwdfjkewww');
                  })
              }
            });
        }

        // db.collection("Connect_List")
        //   .doc(localStorage.getItem("tablealias"))
        //   .collection("chatbot")
        //   .doc(name)
        //   .update(data);

        db.collection("integration_lists")
          .doc(name)
          .collection(name + "_data")
          .doc(localStorage.getItem("tablealias"))
          .collection("chatbot")
          .doc(localStorage.getItem("botId"))
          .set(data)
          .then((doc) => {
            resolve(true);
          });
      })
      .catch((err) => {
        console.log(err.message , 'ljwdfjkew');
      });
  });
};
// export const setHandoffinDBintegrationList = (data,name) => {
// db
//   .collection('integration_lists')
//   .doc(name)
//   .collection(name+'_data')-+

//   .doc(localStorage.getItem("tablealias"))
//   .collection("chatbot")
//   .doc(localStorage.getItem("botId"))
//   .set(data);

export const removeGlobal = (name, data) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(name)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var newArr=doc.data()["accounts"].filter((item,index) => index != data);
          var newData = {}


          newData["accounts"] = newArr
          db.collection("Connect_List")
            .doc(localStorage.getItem("tablealias"))
            .collection("chatbot")
            .doc(name)
            .update(newData)
            .then(()=>{
              console.log("GLOBAL DELETED")
              resolve(true);
            })
           
        }else{
          resolve(true);
        }
      });
  });
};

export const deleteIntegrationInDBConnect = (appName) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(appName.replace(" ", "_").toLowerCase())
      .delete()
      .then(() => {
        console.log("Deleted team data");
        resolve(true);
      })
      .catch((err) => {
        console.log(err, "dbdelfailed");
      });
  });
};

export const updateConnectHandoff = (data, name) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(name)
      .update(data)
      .then(() => {
        console.log("Updated team data");
        resolve(true);
      })
      .catch((err) => {
        console.log(err, "dbupdatefailed");
      });
  });
};

export const getConnectHandoff = (name) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(name)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          resolve({});
        }
      });
  })
}
export const installZendesk = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "zendesk", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallZendesk = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("zendesk").then((doc) => {
      resolve(true);
    });
  });
};

export const installSmtp = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "smtp", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallSmtp = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("smtp").then((doc) => {
      resolve(true);
    });
  });
};

export const getEditEmailData = () => {
  return new Promise(async (resolve, reject) => {
    let data = {};
    await db
      .collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc("appy_email")
      .get()
      .then((doc) => {
        if(doc.exists){
        data = doc.data();
        resolve(data);
      }else{
        resolve({});
      }
      })
      .catch((err) => {
        console.log(err.message);
        resolve({});
      });
  });
};

export const editEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await db
      .collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc("appy_email")
      .set(data)
      .then((doc) => {
        resolve(true);
      })
      .catch((err) => {
        console.log(err.message);
        resolve(false);
      });
  });
};

export const sendOtp = (code, phone) => {
  return new Promise(async (resolve, reject) => {
    console.log(code, " code ", phone, " phone ");
    let otpUrl = API_URL + "/sendOtp";
    fetch(otpUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+getCookie("APPYID")
      },
      body: JSON.stringify({
        code,
        phone,
      }),
    })
      .then((res) => res.json())
      .then((status) => {
        console.log("OTPPPP ==== ", status);
        if (status.status == "pending") {
          resolve(true);
          console.log("loaderfalsefb8");
        } else {
          console.log("____ Invalid phone number _______ ", status);
          resolve(false);
          console.log("loaderfalsefb9");
        }
      })
      .catch((err) => console.log(err));
  });
};

export const verifyOtp = (code, phone, otp) => {
  return new Promise(async (resolve, reject) => {
    // console.log("INTERGETAIOSFSFSOIFOSFFFFF ======= ", this.state)
    let otpUrl = API_URL + "/verifyOtp";
    fetch(otpUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" , "Authorization": "Bearer "+getCookie("APPYID") },
      body: JSON.stringify({ code, phone, otp }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("OTPPPP ==== ", data);
        if (data.status == "approved") {
          resolve(true);
        } else {
          resolve(false);
        }
        //this.setState({verifyOtp : true})
      })
      .catch((err) => console.log(err));
  });
};

export const installWhatsApp = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "whatsapp", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallWhatsApp = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("whatsapp").then((doc) => {
      resolve(true);
    });
  });
};

export const installSms = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "sms", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallSms = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("sms").then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallGoogle = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("gdrive").then((doc) => {
      resolve(true);
    });
  });
};

export const getconnectURL = async (appId, integrationUrl) => {
  return new Promise(async (resolve, reject) => {


    const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }


    var body = [
      `cookie=${document.cookie}&appId=${appId}&botId=${localStorage.getItem(
        "botId"
      )}&integrationUrl=${integrationUrl}&clientId=${localStorage.getItem(
        "tablealias"
      )}&email=${userData.email}&callback_url=${
        window.location.origin
      }/verify`,
    ];
    fetch(
      `${"https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"}/connect_integration_new/integration-url`,
      {
        // `${API_URL}/connect_integration_new/integration-url`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        resolve(response.url);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const fetchMSDropdown = (connectId, nodeId, team) => {
  return new Promise(async (resolve, reject) => {
    var funcName = team ? "getMicrosoftChannelList" : "getMicrosoftTeamList";
    var formField = team
      ? {
          team: team,
        }
      : {};
    await fetch(`${API_URL}/connect_integration_new/trigger-dropdown`, {
      method: "post",
      body: JSON.stringify({
        pc_id: localStorage.getItem("tablealias"),
        botId: localStorage.getItem("botId"),
        connectId,
        nodeId,
        funcName,
        formField,
        cookie: document.cookie,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.success) {
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
  });
};

export const installMSteam = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "microsoft_teams", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};
export const uninstallMSteam = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("microsoft_teams").then((doc) => {
      resolve(true);
    });
  });
};

export const fetchZHDropdown = (connectId, nodeId,funcName,team) => {
  return new Promise(async (resolve, reject) => {
    // var funcName = funcName;
    var formField = team
      ? {
        organizationId: team,
        }
      : {};
    await fetch(`${API_URL}/connect_integration_new/trigger-dropdown`, {
      method: "post",
      body: JSON.stringify({
        pc_id: localStorage.getItem("tablealias"),
        botId: localStorage.getItem("botId"),
        connectId,
        nodeId,
        funcName,
        formField,
        cookie: document.cookie,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.success) {
          var resArr=response.data.filter((item)=>item.value!=="")
          resolve(resArr);
        } else {
          resolve([]);
        }
      });

  })
}


export const installZohoDesk = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "zoho_desk", addGlobal).then((doc) => {
      resolve(true);
    });
  });
}

export const uninstallZohoDesk = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("zoho_desk").then((doc) => {
      resolve(true);
    });
  });
}

export const installSalesForce = (data) => {
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "salesforce").then((doc) => {
      resolve(true);
    });
  });
}

export const uninstallSalesForce = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("salesforce").then((doc) => {
      resolve(true);
    });
  });
}

export const installZoom=(data)=>{
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "zoom").then((doc) => {
      resolve(true);
    });
  });

}

export const uninstallZoom=()=>{
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("zoom").then((doc) => {
      resolve(true);
    });
  });
}


export const getAllAccounts = (appId)=>{
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
    .doc(localStorage.getItem("tablealias"))
    .collection("chatbot")
    .doc(appId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        resolve(doc.data()["accounts"]?doc.data()["accounts"]:[]);
      } else {
        resolve([]);
      }
    })
  
  })

}


export const getGptData = (bot_id) => {
  return new Promise((resolve, reject) => {

     db
    .collection('user_' + localStorage.getItem('tablealias'))
    .doc(localStorage.getItem('agent_id'))
    .collection('chatbot')
    .doc(bot_id)
    .get().then(doc=>{ 
    
      if(doc.exists){
        var data=doc.data().gptData?doc.data().gptData:{}
        console.log(doc.data(),"gptDatagptDatagptDatagptDatagptData")
        resolve(data)
      }
    })
    })

}
 export const updateGptData = async (gptDataG, data, type, source) => {
   return new Promise(async (resolve, reject) => {
     console.log(
       gptDataG,
       data,
       "TYPE",
       type,
       source,
       "SOURCE    SJKDBIBNADIUBDAUBDUIABUIBDBAD"
     );

     var gptData=JSON.parse(JSON.stringify(gptDataG));

     var newData = {};

     newData["import_files"] = gptData["import_files"];
     newData["pcText"] = gptData["initText"];
     newData["import_urls"] = gptData["import_urls"];
     if(gptData["allowedFiles"]){
     newData["allowedFiles"]=gptData["allowedFiles"];
     }
      if(gptData["allowedUrls"]){
      newData["allowedUrls"]=gptData["allowedUrls"];
      }
      if(gptData["totalCount"]){
      newData["totalCount"]=gptData["totalCount"];
      }
      if(gptData["totalSize"]){
        newData["totalSize"]=gptData["totalSize"];
      }

     if (type == "init") {
       var initData = newData;
       console.log("DATA TO UPDATE IS", initData);

       db
         .collection("user_" + localStorage.getItem("tablealias"))
         .doc(localStorage.getItem("agent_id"))
         .collection("chatbot")
         .doc(localStorage.getItem("botId"))
         .update({ gptData: initData })
         .then((data) => {
           console.log("UPDATED", data);
           // resolve(initData);
           resolve(Object.assign({}, gptData, initData));
         });
     } else if (source == "file") {
       if (type == "add") {
        //  console.log("DATA TO UPDATE IS ADDD TYPE");
        //  data.map((item, index) => {
        //    const foundIndex = newData["import_files"].findIndex(
        //      (element) => element.name === item.name
        //    );
        //    console.log("FOUND INDEX", foundIndex, item.name);
        //    if (foundIndex > -1) {
        //      console.log("FOUND INDEX", foundIndex, "ADD TOKENS");
        //      newData["import_files"][foundIndex]["tokens"] = item.len;
        //    }
        //  });
        //  var addData = newData;
        //  console.log("DATA TO UPDATE IS", addData);
        //  db
        //    .collection("user_" + localStorage.getItem("tablealias"))
        //    .doc(localStorage.getItem("agent_id"))
        //    .collection("chatbot")
        //    .doc(localStorage.getItem("botId"))
        //    .update({ gptData: addData })
        //    .then((data) => {
        //      console.log("UPDATED" , addData);
        //      resolve(Object.assign({}, gptData, addData));
        //    });
       } else if (type == "delete") {
         var files = newData["import_files"];

         data.map((item, index) => {
           files = files.filter((element) => element.name !== item.name);
         });
         newData["import_files"] = files;
         var delData = newData;
         console.log("DATA TO DELETE IS", delData);

         db
           .collection("user_" + localStorage.getItem("tablealias"))
           .doc(localStorage.getItem("agent_id"))
           .collection("chatbot")
           .doc(localStorage.getItem("botId"))
           .update({ gptData: delData })
           .then((data) => {
             console.log("UPDATED" , delData);
             // resolve(delData)
             resolve(Object.assign({}, gptData, delData));
           });
       }
     } else if (source == "text") {
      //  newData["pcText"] = gptData["pcText"];
      //  db
      //    .collection("user_" + localStorage.getItem("tablealias"))
      //    .doc(localStorage.getItem("agent_id"))
      //    .collection("chatbot")
      //    .doc(localStorage.getItem("botId"))
      //    .update({ gptData: newData })
      //    .then((data) => {
      //      console.log("UPDATED");
      //      resolve(Object.assign({}, gptData));
      //    });
     } else if (source == "url") {
       if (type == "add") {
        //  console.log("DATA TO UPDATE IS ADDD TYPE");
        //  data.map((item, index) => {
        //    const foundIndex = newData["import_urls"].findIndex(
        //      (element) => element.name === item.name
        //    );
        //    console.log("FOUND INDEX", foundIndex, item.name);
        //    if (foundIndex > -1) {
        //      console.log("FOUND INDEX", foundIndex, "ADD TOKENS");
        //      newData["import_urls"][foundIndex]["tokens"] = item.len;
        //    }
        //  });
        //  var addData = newData;
        //  console.log("DATA TO UPDATE IS", addData);
        //  db
        //    .collection("user_" + localStorage.getItem("tablealias"))
        //    .doc(localStorage.getItem("agent_id"))
        //    .collection("chatbot")
        //    .doc(localStorage.getItem("botId"))
        //    .update({ gptData: addData })
        //    .then((data) => {
        //      console.log("UPDATED");
        //      resolve(Object.assign({}, gptData, addData));
        //    });
       } else if (type == "delete") {
         var files = newData["import_urls"];
         data.map((item, index) => {
           files = files.filter((element) => element.name !== item.name);
         });
         newData["import_urls"] = files;
         var delData = newData;
         console.log("DATA TO DELETE IS", delData);

         db
           .collection("user_" + localStorage.getItem("tablealias"))
           .doc(localStorage.getItem("agent_id"))
           .collection("chatbot")
           .doc(localStorage.getItem("botId"))
           .update({ gptData: delData })
           .then((data) => {
             console.log("UPDATED");
             // resolve(delData)
             resolve(Object.assign({}, gptData, delData));
           });
       }
     }
   });
 };

 const updateGptUrlData = async (item) => {

  return new Promise((resolve) => {

  if(item){

  var gptData=recurringGptData

     var newData = {};

     newData["import_files"] = gptData["import_files"];
     newData["pcText"] = gptData["initText"]?gptData["initText"]:"";
     newData["import_urls"] = gptData["import_urls"];
     if(gptData["allowedFiles"]){
     newData["allowedFiles"]=gptData["allowedFiles"];
     }
      if(gptData["allowedUrls"]){
      newData["allowedUrls"]=gptData["allowedUrls"];
      }
      if(gptData["totalCount"]){
      newData["totalCount"]=gptData["totalCount"];
      }
      if(gptData["totalSize"]){
        newData["totalSize"]=gptData["totalSize"];
      }

      
        const foundIndex = newData["import_urls"].findIndex(
          (element) => element.name === item.name
        );
        console.log("FOUND INDEX", foundIndex, item.name);
        if (foundIndex > -1) {
          console.log("FOUND INDEX", foundIndex, "ADD TOKENS");
          newData["import_urls"][foundIndex]["tokens"] = item.len ? item.len : 0;
          if(item.error){
            newData["import_urls"][foundIndex]["error"] =true
          }else{
            newData["import_urls"][foundIndex]["error"] = false
          }
        }
      
      recurringGptData = newData;
      // console.log("DATA TO UPDATE IS", addData);
      db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(localStorage.getItem("botId"))
        .update({ gptData: recurringGptData })
        .then((data) => {
          console.log("UPDATED");
          resolve(true)
          // resolve(Object.assign({}, gptData, addData));
        });


      }
    })


 }

 const updateGptFileData = async ( item) => {

return new Promise((resolve) => {


  
  if(item){

  var gptData=recurringGptData

  var newData = {};

  newData["import_files"] = gptData["import_files"];
  newData["pcText"] = gptData["initText"]?gptData["initText"]:"";
  newData["import_urls"] = gptData["import_urls"];
  if(gptData["allowedFiles"]){
  newData["allowedFiles"]=gptData["allowedFiles"];
  }
   if(gptData["allowedUrls"]){
   newData["allowedUrls"]=gptData["allowedUrls"];
   }
   if(gptData["totalCount"]){
   newData["totalCount"]=gptData["totalCount"];
   }
   if(gptData["totalSize"]){
    newData["totalSize"]=gptData["totalSize"];
  }
   
     const foundIndex = newData["import_files"].findIndex(
       (element) => element.name === item.name
     );
     console.log("FOUND INDEX", foundIndex, item.name);

     console.log(newData , ' dfghjkl')
     if (foundIndex > -1) {
       console.log("FOUND INDEX", foundIndex, "ADD TOKENS");
       newData["import_files"][foundIndex]["tokens"] = item.len? item.len : 0;
       if(item.error){
         newData["import_files"][foundIndex]["error"] =true
       }else{
        newData["import_files"][foundIndex]["error"] = false
      }
     }
   
   var addData = newData;
   console.log("DATA TO UPDATE IS", addData);
   db
     .collection("user_" + localStorage.getItem("tablealias"))
     .doc(localStorage.getItem("agent_id"))
     .collection("chatbot")
     .doc(localStorage.getItem("botId"))
     .update({ gptData: addData })
     .then((data) => {
       console.log("UPDATED" , addData);
       resolve(true)
       // resolve(Object.assign({}, gptData, addData));
     });
    }
  })

 }

  
  export const trainGptData = (alias,addList) => {
    return new Promise((resolve, reject) => {

      var raw = JSON.stringify({
        "alias": alias,
        "addList":addList
      });
      
      var requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' , "Authorization": "Bearer "+getCookie("APPYID")},
        body: raw,
        redirect: 'follow'
      };
      
      fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/gpt/train", requestOptions)
      // fetch("http://localhost:5051/chatbot-production-d6ea3/us-central1/gpt/train", requestOptions)

        .then(response => response.json())
        .then(result =>{resolve(result)})
        .catch(error => console.log('error', error))
      })
  
  }
  export const removeTrainGptData = (alias,delList) => {
    return new Promise((resolve, reject) => {

      var raw = JSON.stringify({
        "alias": alias,
        "addList":delList
      });
      
      var requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
        body: raw,
        redirect: 'follow'
      };
      
      fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/gpt/delete", requestOptions)
        .then(response => response.json())
        .then(result =>{resolve(result)})
        .catch(error => console.log('error', error))
      })
  
  }
export const textToBot=async (description)=>{
  return new Promise(async (resolve, reject) => {
  if(process.env.NODE_ENV=="production"){

    const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }
    var raw = JSON.stringify({
      "action": "aiPrompt",
      "email": userData.email,
      "prompt": description
    });
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/querylog/insert", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  // var prompt=`[
  //   { "nodeType": 5, "name": "Date Selector & Time Slot" },
   
  //   { "nodeType": 7, "name": "Single Choice Button" },
  //   { "nodeType": 16, "name": "Date Selector" },
  //   { "nodeType": 13, "name": "File Upload" },
  //   { "nodeType": 24, "name": "Multiple Choice Button" },
  //   { "nodeType": 15, "name": "Gender" },
  //   { "nodeType": 20, "name": "Get Address" },
  
  //   { "nodeType": 2, "name": "Capture Email" },
  
  //   { "nodeType": 1, "name": "Capture name" },
  //   { "nodeType": 3, "name": "Capture Phone Number" },
  //   { "nodeType": 8, "name": "Bot Question" },
    
  //   { "nodeType": 22, "name": "Send Media" },
  //   { "nodeType": 18, "name": "Survey" }
  // ]
  // Use this json.
  
  // Use options from this json options to make a bot flow for return bot and write a json of format {"botName":"","botFlow":[]} with array of objects with "nodeType","name", and text generated by you to make a bot as per requirement below.
  // Use "nodeType" integer from json .
  // If you want user to choose among answer use node button and "options".
  // "options" has to be a an array of texts .
  //  Options cannot be included with any other node.
  //  Do not ask questions sepearately in Bot Message.
  //  "Date & Time" has both date and time so you need not include "Date selector" seperately if you need both date and time.
  //  Add question in button node text if required not two seperated repeated nodes.
  // Use Bot Message only for acknowledgement. Do not  include Thank you message for Get Email,Phone,Address,Question.
  // Try upto 10 nodes.
  // Do not write  a welcome message as we add it already.
  // Requirement: Create a bot to `+
  let prompt=description

   await generateJson(prompt).then(answer=>{
      
    console.log("BOT FLOW IS READY",typeof answer,answer)
    let startIndex = answer.indexOf('{');
    let endIndex = answer.lastIndexOf('}');

    if (startIndex !== -1 && endIndex !== -1) {
       answer = answer.substring(startIndex, endIndex + 1);
      
    } else {
      console.log('Invalid JSON string');
    }
    var raw = typeof answer==typeof "abc"?JSON.stringify(JSON.parse(answer).botFlow):JSON.stringify(answer.botFlow);
    var botName=typeof answer==typeof "abc"?JSON.stringify(JSON.parse(answer).botName):JSON.stringify(answer.botName)
    localStorage.setItem("BOTNAME",botName.replace(/[^\w\s]/g, ''))
    
    // localStorage.setItem("BotName",botName)

    console.log("BOT FLOW IS READY",typeof answer,raw,botName)
    var requestOptions = {
      method: 'POST',
      headers:  {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
      body: raw,
      redirect: 'follow'
    };
    // fetch("http://localhost:5010/chatbot-production-d6ea3/us-central1/makeBotNew", requestOptions)

    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"+"/makeBotNew", requestOptions)
      .then(response => response.json())
      .then(result => {
        // res.status(200).send(result)
        resolve(result)
      
      })
      .catch(error => {
        // res.status(300).send("ERROR")
        // console.log('error', error)
        resolve(false)
      });
    
    

  }).catch(err=>{
    console.log(err)
    resolve(false)


    
  })
})

}

// async function generateJson(prompt) {
//   // console.log(prompt,"PROMPT")
//   return new Promise(async (resolve, reject) => {

//   db.collection("Api_Key").doc("Preview").get().then(async keys=>{
//     if(keys.exists){

//     const openaiApiKey = keys.data().openAI
//   const apiUrl = 'https://api.openai.com/v1/engines/text-davinci-003/completions';

//   const body = {
//     prompt: prompt,
//     temperature:0,
//     max_tokens:2048,
//     n:1,
//     stop:'None',
    
//   };

//   const response = await fetch(apiUrl, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${openaiApiKey}`
//     },
//     body: JSON.stringify(body)
//   });

//   const result = await response.json();
//   // console.log(result,"DDATATATTATATTATA")
//   // return true
//   if (result.error){
//     // res.status(200).json({ data: result.error.message });
//     console.log("ERROR IN AI RESPONSE")
//     resolve(result.error.message);
//   }else{
//   console.log(typeof result.choices[0].text,"AI RESPONSE")
  
//   const message = result.choices[0].text
//   resolve(message);
//   // res.status(200).json({ data: aiResponse });
//   }
// }

//   })

  
//   // const message = data.choices[0].text;

//   // resolve(message);
// })
// }
// async function generateJson(prompt) {
//   return new Promise(async (resolve, reject) => {
//     db.collection("Api_Key").doc("Preview").get().then(async keys => {
//       if (keys.exists) {
//         const openaiApiKey = keys.data().openAI;
//         const apiUrl = 'https://api.openai.com/v1/chat/completions';  // Updated API endpoint

//         const body = {
//           model: 'gpt-3.5-turbo',  // Specify the GPT-3.5 Turbo model
//           messages: [
//             { role: 'system', content: 'You are a helpful assistant.' },
//             { role: 'user', content: prompt },
//           ],
//         };

//         const response = await fetch(apiUrl, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${openaiApiKey}`,
//           },
//           body: JSON.stringify(body),
//         });

//         const result = await response.json();

//         if (result.error) {
//           console.log("ERROR IN AI RESPONSE");
//           resolve(result.error.message);
//         } else {
//           console.log(typeof result.choices[0].message.content, "AI RESPONSE");
//           const message = result.choices[0].message.content;
//           resolve(message);
//         }
//       }
//     });
//   });
// }

async function generateJson(prompt){
  return new Promise(async (resolve, reject) => {
    
    const body={
      userInput:prompt,
      companyId:localStorage.getItem("tablealias"),
    }
    // const response= await fetch("http://localhost:5693/chatbot-production-d6ea3/us-central1/runAiModel/getBotTemplate",{
    const response = await fetch(API_URL+"/runAiModel/getBotTemplate", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+getCookie("APPYID")
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();

    if (!result.success) {
      console.log("ERROR IN AI RESPONSE");
      resolve(result.message);
    } else {
      // console.log(typeof result.choices[0].message.content, "AI RESPONSE");
      const message = result.message;
      resolve(message);
    }
  })

}


export const getPrompt = async(userId) => {
  return new Promise(async (resolve, reject) => {
    db.collection("TextToBot").doc(userId).get().then(doc=>{
      if(doc.exists){
        console.log(doc.data(),"PROMPT")
        resolve({"status":true,"prompt":doc.data().user_prompt})
      }else{
        resolve({"status":false})
      }
    })
  })
}

// export const makeBotInDb = async(botId) => {
//   return new Promise(async (resolve, reject) => {
//     console.log("MAKING BOT IN DB",botId)
  
// var chatBotAvtar=custom.chatBotAvtars.chatBotAvtar
// var widgetImage=custom.widgetImages.widgetImage
// if(localStorage.getItem('aiSteps')){
//   custom["template_One"]=JSON.parse(localStorage.getItem('aiSteps'))
//   // localStorage.removeItem('aiSteps')
  
//   for (const key in custom.template_One) {
//     if (custom.template_One[key].UI == 'Handoffs') {
//       var handoffs_ = custom.template_One[key].data.handoffs
//       for (const key in handoffs_) {
//         handoffs_[key].data.email = localStorage.getItem('email')
//       }
//     }
//   }
//   custom.template_One.customisation.headerName = localStorage.getItem("BOTNAME")?localStorage.getItem("BOTNAME"):"AI Bot"
//   custom.template_One['0'].data.text = custom.template_One[
//     '0'
//   ].data.text.replace('Snappy', `{{bot_name}}`)
//   var bot_details = {
//     bot_name: localStorage.getItem("BOTNAME")?localStorage.getItem("BOTNAME").replace(/"/g,''):"AI Bot",
//     bot_template_category: 17,
//     create_date: new Date().getTime(),
//     update_date: new Date().getTime(),
//     isDelete: 0
//   }
//   localStorage.setItem("botTemp",17)
//   first_time_saveDataFlow(
//     botId,
//     custom.template_One,
//     bot_details,
//    chatBotAvtar,
//     widgetImage
//   )
//   resolve(true)
// }else{
//   NotificationManager.error("Please create a bot first")
//   resolve(false)
// }
//   })

// }

export const getPromptsStatic = async() => {
  return new Promise(async (resolve, reject) => {
    db.collection("TextToBot").doc("promptList").get().then(doc=>{
      if(doc.exists){
       console.log(doc.data().promptList,"PROMPT LIST")
        resolve(doc.data().promptList)
      }else{
        resolve(false)
      }
    })
  })
}


// export const trainPC = (alias,addList) => {
//   return new Promise((resolve, reject) => {

//     var raw = JSON.stringify({
//       "nameSpace": alias,
//       "addList":addList?addList:[]
//     });
    
//     var requestOptions = {
//       method: 'POST',
//       headers: {'Content-Type': 'application/json'},
//       body: raw,
//       redirect: 'follow'
//     };
    
//     fetch(API_URL + "/pineCone/train", requestOptions)
//     // fetch("http://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/train", requestOptions)

//       // .then(response => response.json())
//       .then(result =>{
//         if (result.status == 200 ){
//           resolve(true)
        
//         }else{
//           resolve(false)
//           }
//         })
//       .catch(error => console.log('error', error))
//     })

// }
export const deletePC = (alias,delList) => {
  return new Promise((resolve, reject) => {

    var raw = JSON.stringify({
      "nameSpace": alias,
      "delList":delList
    });
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
      body: raw,
      redirect: 'follow'
    };
    
    fetch(API_URL + "/pineCone/delete", requestOptions)
    // fetch("http://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/delete", requestOptions)

      // .then(response => response.json())
      .then(result =>{resolve(true)})
      .catch(error => {
        console.log('error hola', error)
        resolve(false)

    })
    })

}

export const deleteCanopy = (alias,delList) => {

  return new Promise(async (resolve, reject) => {

    const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }

    var isSingle=userData.email.includes("@appypie") || userData.email.includes("@yopmail")

    var raw =JSON.stringify({nameSpace:localStorage.getItem("botId"),indexName:isSingle?PINECONE_NAME:PINECONE_CLIENT_NAME ,delList})

    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
      body: raw,
      redirect: 'follow'
    };
    
    fetch(API_URL + "/canopy/delete", requestOptions)
    // fetch("http://localhost:5783/query/delete", requestOptions)

      // .then(response => response.json())
      .then(result =>{resolve(true)})
      .catch(error => {
        console.log('error hola', error)
        resolve(false)

    })
    })

}



export const trainPC = (addList,source,gptDataGlobal) => {

  console.log(addList,"ADD LIST",source)
   recurringGptData=JSON.parse(JSON.stringify(gptDataGlobal))
    return new Promise(async (resolve, reject) => {

        await trainPCDoc(addList,0,source).then(result=>{
          console.log(result,"RESULT of trainPCALL",addListPC)
          if(result){
            resolve(addListPC)
          }else{
            resolve(false)
          }
        })
    })
}

export const trainPCNew = (addList,source,gptDataGlobal) => {
    console.log(addList,"ADD LIST",source)
    recurringGptData=JSON.parse(JSON.stringify(gptDataGlobal))
      return new Promise(async (resolve, reject) => {
  
          await trainPCDocNew(addList,0,source).then(result=>{
            console.log(result,"RESULT of trainPCALL",addListPC)
            if(result){
              resolve(addListPC)
            }else{
              resolve(false)
            }
          })
      })
  
}

export const trainPCDoc=(docs,index,source)=>{
  return new Promise((resolve, reject) => {

  console.log(docs,index,"DOC cond",index<docs.length)
  if(index<docs.length){
    // console.log(docs[index],"DOC CALLED API")
    callTrainUrl(docs[index],source).then(result=>{
      console.log(result,"RESULT of trainPCALL URL API")

      if(result.status){
        // UPDATE DB
        addListPC.push(result)
        resolve(trainPCDoc(docs,index+1,source))
      }else{
        // UPDATE DB
        resolve(trainPCDoc(docs,index+1,source))

      }

      }).catch(err=>{
        // UPDATE DB
        trainPCDoc(docs,index+1,source)
      })
      }else{
       resolve(true)
      }
    })
}

export const trainPCDocNew=(docs,index,source)=>{
  return new Promise((resolve, reject) => {

    console.log(docs,index,"DOC cond",index<docs.length)
    if(index<docs.length){
      // console.log(docs[index],"DOC CALLED API")
      callTrainUrlNew(docs[index],source).then(result=>{
        console.log(result,"RESULT of trainPCALL URL API")
  
        if(result.status){
          // UPDATE DB
          addListPC.push(result)
          resolve(trainPCDocNew(docs,index+1,source))
        }else{
          // UPDATE DB
          resolve(trainPCDocNew(docs,index+1,source))
  
        }
  
        }).catch(err=>{
          // UPDATE DB
          trainPCDocNew(docs,index+1,source)
        })
        }else{
         resolve(true)
        }
      })





}

export const callTrainUrl=(body,source )=>{
  return new Promise((resolve, reject) => {
 
    if(source=="file"){
    getUnstructuredUrlText(body.url).then(text=>{
      if(text){
        // console.log(text,"API URL is result text")
        trainText(text,body.name).then(result=>{
          updateGptFileData(result).then(res => {
            resolve(result)
          })
          
        })
      }else{
        updateGptFileData({len:0,error:true,name:body.name}).then(res => {
          resolve(false)
        })
        // resolve(false)
      }
    })
  }else if (source=="url"){
    console.log(body.url,"API URL input fot result text")
    getUnstructuredUrlText(body.url ).then(text=>{
      if(text){
        console.log(text,"API URL is result text")
        trainText(text,body.name).then(result=>{
          updateGptUrlData(result).then(res => {
            resolve(result)
          })
          // resolve(result)
        })
      }else{
        updateGptUrlData({len:0,error:true,name:body.name}).then(res => {
          resolve(false)
        })
          // resolve(false)

      }
    })


  }
  

  
})
}

export const callTrainUrlNew=(body,source )=>{

  return new Promise((resolve, reject) => {
 
    if(source=="file"){
    getUnstructuredUrlText(body.url).then(text=>{
      if(text){
        // console.log(text,"API URL is result text")
        trainTextNew(text,body.name,body.fileId).then(result=>{
          updateGptFileData(result).then(res => {
            resolve(result)
          })
          
        })
      }else{
        updateGptFileData({len:0,error:true,name:body.name}).then(res => {
          resolve(false)
        })
        // resolve(false)
      }
    })
  }else if (source=="url"){
    console.log(body.url,"API URL input fot result text")
    getUnstructuredUrlText(body.url ).then(text=>{
      if(text){
        console.log(text,"API URL is result text")
        trainTextNew(text,body.name,body.fileId).then(result=>{
          updateGptUrlData(result).then(res => {
            resolve(result)
          })
          // resolve(result)
        })
      }else{
        updateGptUrlData({len:0,error:true,name:body.name}).then(res => {
          resolve(false)
        })
          // resolve(false)

      }
    })


  }
})


}


export const trainText=(text,name)=>{

  return new Promise((resolve, reject) => {

    // Replace one or more newline characters with a space
text = text.replace(/\n+/g, ' ');

// Replace two or more consecutive dots with a single dot
text = text.replace(/\.{2,}/g, '.');
  var raw =JSON.stringify({text,nameSpace:localStorage.getItem("botId"),name})
  console.log(raw,"RAW TEXT")
  var apiUrl="https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/pineCone/trainText"
  // http://localhost:5079/chatbot-production-d6ea3/us-central1/pineCone/
  // var apiUrl="http://localhost:5079/chatbot-production-d6ea3/us-central1/pineCone/trainText"

  var requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
    body: raw,
    redirect: 'follow'
  };
  // console.log(apiUrl,"API URL is this final")
  fetch(apiUrl, requestOptions)
    
  .then(result => {
    console.log(result,"API URL is of trainText result")
    if(result.status==200){
      return result.json()
      
    }else{
     return false
    }
    
    
    // console.log(result)
  }).then(text=>{
    if(text){
      resolve(text)
    }
    else{
      resolve({name : name , error : true })
    }
  })
    .catch(error => {resolve(false)})


  })




}

export const trainTextNew=(text,name,fileId)=>{

  return new Promise(async (resolve, reject) => {

    const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }

    var isSingle=userData.email.includes("@appypie") || userData.email.includes("@yopmail")

    // Replace one or more newline characters with a space
text = text.replace(/\n+/g, ' ');

// Replace two or more consecutive dots with a single dot
text = text.replace(/\.{2,}/g, '.');
  var raw =JSON.stringify({text,nameSpace:localStorage.getItem("botId"),indexName:isSingle?PINECONE_NAME:PINECONE_CLIENT_NAME,name,fileId})
  console.log(raw,"RAW TEXT")
  // var apiUrl="http://localhost:5783/query/ingest"
  var apiUrl=API_URL+"/canopy/ingest"

  var requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
    body: raw,
    redirect: 'follow'
  };
  // console.log(apiUrl,"API URL is this final")
  fetch(apiUrl, requestOptions)
    
  .then(result => {
    console.log(result,"API URL is of trainText result")
    if(result.status==200){
      return result.json()
      
    }else{
     return false
    }
    
    
    // console.log(result)
  }).then(text=>{
    if(text){
      resolve(text)
    }
    else{
      resolve({name : name , error : true })
    }
  })
    .catch(error => {resolve(false)})


  })




}

export const getUnstructuredUrlText=(url)=>{
  return new Promise((resolve, reject) => {

   

var raw = JSON.stringify(
 
    {url}
  
);

var requestOptions = {
  method: 'POST',
  headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID")},
  body: raw,
  redirect: 'follow'
};
// fetch("https://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/getUnstText", requestOptions)
fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/pineCone/getUnstText", requestOptions)
  // .then(response => response.json())

  .then(result => {
    // console.log(result,"API URL is result")
    if(result.status==200){
      return result.json()
      
    }else{
     return false
    }
    
    
    // console.log(result)
  }).then(text=>{
    if(text.body){
      resolve(text.body)
    }
    else{
      resolve(false)
    }
  })
  .catch(error => {
    console.log('error', error)
    resolve(false)
  });

  })
}

export const getTextFromUrl=(url)=>{
  // return new Promise(async (resolve, reject) => {
  //   var raw = JSON.stringify({
  //     "method": "url",
  //     "url": url
  //   });
    
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: {"Content-Type": "application/json"},
  //     body: raw,
  //     redirect: 'follow'
  //   };
    
  //   fetch("https://us-central1-appycx-beta.cloudfunctions.net/getText", requestOptions)
  //   .then(result => {
  //     console.log(result,"API URL is result")
  //     if(result.status==200){
  //       return result.json()
        
  //     }else{
  //      return false
  //     }
      
      
  //     // console.log(result)
  //   }).then(text=>{
  //     if(text){
  //       resolve(text.body)
  //     }
  //     else{
  //       resolve(false)
  //     }
  //   })
  //   .catch(error => {
  //     console.log('error', error)
  //     resolve(false)
  //   });

  // })
  return new Promise((resolve, reject) => {

   

    var raw = JSON.stringify(
     
        {url}
      
    );
    // var urls=[]
    // urls.push(url)
    // var raw= JSON.stringify({"urls":[url]})
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json',  "Authorization": "Bearer "+getCookie("APPYID"), cookie: "adasd"},
      body: raw,
      redirect: 'follow'
    };
    // fetch("http://localhost:5079/chatbot-production-d6ea3/us-central1/pineCone/getUrlText", requestOptions)
    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/pineCone/getUnstText", requestOptions)
    // fetch("https://us-central1-appycx-beta.cloudfunctions.net/unstructured_text", requestOptions)
      // .then(response => response.json())
    
      .then(result => {
        // console.log(result,"API URL is result")
        if(result.status==200){
          return result.json()
          
        }else{
         return false
        }
        
        
        // console.log(result)
      }).then(text=>{
        if(text){
          resolve(text.body)
        }
        else{
          resolve(false)
        }
      })
      .catch(error => {
        console.log('error', error)
        resolve(false)
      });
    
      })


}


export const updateApptConfig=(data)=>{
  return new Promise((resolve, reject) => {

    var body={}
    body[`appointment.configuration`]=data
    console.log(body , ' kghikghjkg')
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .update(body).then(result=>{
      
      console.log("UPDATED APPT CONFIG")
      resolve(true)
    })
    .catch(error => {
      console.log('error', error)
      resolve(false)
    })
  })
}
export const saveApptWorkHours=(data)=>{
  return new Promise((resolve, reject) => {
    var body={}
    body[`appointment.workingHours.workingHours`]=data  
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .update(body).then(result=>{
      console.log("UPDATED APPT CONFIG")
      resolve(true)
    })
    .catch(error => {
      console.log('error', error)
      resolve(false)
    })


  
  })

}

export const moveTempfromTemp=async ()=>{
  return new Promise((resolve, reject) => {

    
    
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .collection("chatbot")
    .doc(localStorage.getItem("botId"))
    .get().then(doc=>{
      
      var dashData = doc.data()
      if(dashData.templateTemp){
        
      dashData["template"] = dashData.templateTemp
      delete dashData["templateTemp"]
      db.collection("user_"+localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .set(dashData).then(result=>{
        
        resolve(true)
      })

      }else{
        resolve(true)
      }
      
    }).catch((err) => {
      resolve(false)
    })
    


  })

}

export const updateTemplateinDB= async (data,name,isFree=false)=>{
  
  return new Promise((resolve, reject) => {

    console.log(data,"DATA TO UPDATE",name)
    var fields=isFree?{templateTemp:data.template_One,
      chatBotAvtars : data.chatBotAvtars,
      widgetImages : data.widgetImages
    }:{template:data.template_One,
      chatBotAvtars : data.chatBotAvtars,
      widgetImages : data.widgetImages
    }
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .collection("chatbot")
    .doc(localStorage.getItem("botId"))
    .update(fields).then(result=>{
      console.log(result , ' ghj')
      // 
      var dashData = {}
      dashData[`${localStorage.getItem('botId')}.bot_name`] = name
      dashData[`${localStorage.getItem('botId')}.update_date`] = new Date().getTime()
      db.collection("user_"+localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc("dashboard")
      .update(dashData).then(result=>{
        
        resolve(true)
      })
    }).catch((err) => {
      resolve(false)
    })
    


  })
}




//temporary function for development purpose only
export const updateTemplateinDbManually= async (template , botId ,ta , aid)=>{
  
  return new Promise((resolve, reject) => {

    
    db.collection("user_"+ta)
    .doc(aid)
    .collection("chatbot")
    .doc(botId)
    .update({template : template}).then(result=>{
      console.log(result , ' result of the manual updarte');
      resolve(true)
      // 
      
    }).catch(err => {
      console.log(err , ' error in the manual update');
      reject('error in the manual update')
    })

  })
}

//saving only the bot name in db
//parameters are botId and data
//return boolean
export const saveBotNameInDb= async (botId , name)=>{
  return new Promise((resolve, reject) => {

    

    var dashData = {}
    dashData[`${botId}.bot_name`] = name
    dashData[`${botId}.update_date`] = new Date().getTime()
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .collection("chatbot")
    .doc("dashboard")
    .update(dashData).then(result=>{
      resolve(true)
    }).catch((err) => {
      resolve(false)
    })

  })
}

export const isAnyPaymentinAcc=()=>{
  return new Promise(async (resolve, reject) => {

      const userData = await getUserData(getCookie("APPYID"))

    // resolve(false)
      db.collection("DheerajPaymentTest").where("email","==",userData.email).limit(1).get().then(async docs=>{
      console.log(docs.docs.length,"DOCS  PAYMENT LENGTH")
      if(docs.docs.length>0){
        resolve(true)
      }else{
        resolve(false)
      }
    })
    .catch(err=>{
      resolve(false)
    })
  })
}

export const getFileUrl = async (file , path , allowedMimeTypes) => {
    
  return new Promise(async (resolve, reject) => { 

        // var allowedMimeTypes = ['image/jpeg','image/jpg' , 'image/png'];
        console.log(file , ' kdjchadjshchjcfhnabdscfhbg')        

        console.log(file.type , ' kjbhjkh')
        console.log(allowedMimeTypes.includes(file.type) , ' lhjkhjkhkjh')
        if (!file) {
          console.error('Please select a file before submitting.');
          reject('Please upload a file');
        }


        
        else if(!allowedMimeTypes.includes('all') && !allowedMimeTypes.includes(file.type)){
          
          reject('Invalid file format');
        }
        // else{
        
        // //creating form data object
        // //need to crerate when sending file tio the server
        // const formData = new FormData();
        // formData.append('file', file);
    
        // try {
        //   const FIREBASE_URL = "http://localhost:5000/chatbot-production-d6ea3/us-central1/imageUpload/upload"
        //   const LOCAL_URL = 'http://localhost:3001/upload'
        //   // 
        //   const response = await axios.post(FIREBASE_URL, formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   });
    
        //   console.log('File uploaded successfully', response.data);


        //   if(response && response.data){
        //         if(response.data.isSecureFile){
        //           //uploading the image file to firestore
        //           var storageRef = storage.ref();     
        //           var fileRef = storageRef.child(
        //             path+ "/" + file.name
        //           );
        //           fileRef.put(file).then((snapshot) => {
        //             console.log("Uploaded a blob or file!");
        //             fileRef.getDownloadURL().then(async (url) => {
        //             var newUrl=STORAGE_URL+url.split("?")[0].split("/o/")[1].replace(/%2F/g,"/")
        //               resolve(newUrl);
        //             })
        //           })
        //         }
        //         else{
        //             reject('Invalid File Content')
        //         }
        //   }
        //   else{
        //     reject('Something went wrong')
        //   }

          
        //   // Handle success as needed
        // } catch (error) {
        //   console.error('Error uploading file', error);
        //   reject('Something went wrong')
        //   // Handle error as needed
        // }
     

       
          
        
        // }



        else{
          
            const reader = new FileReader();
      
            reader.onload = (e) => {
              const fileContent = e.target.result;
      
              // Check if the file content contains SVG or XML
              if (fileContent.includes('<svg') || fileContent.includes('<xml') || fileContent.includes('<!DOCTYPE xml')) {
                console.log('File contains SVG or XML content');
                reject('Invalid file content')
              } else {
                console.log('File does not contain SVG or XML content');
                          var storageRef = storage.ref();     
                          var fileRef = storageRef.child(
                            path+ "/" + file.name
                          );
                          fileRef.put(file).then((snapshot) => {
                            console.log("Uploaded a blob or file!");
                            fileRef.getDownloadURL().then(async (url) => {
                            var newUrl=STORAGE_URL+url.split("?")[0].split("/o/")[1].replace(/%2F/g,"/")
                              resolve(newUrl);
                            })
                          })
              }
            };
      
            reader.readAsText(file);
            
         
        }
        
      })
    
  
};






export const getAllUsers = () => {
  return new Promise(async (resolve, reject) => {
    var agent_list = []
    db
      .collection('user_' + localStorage.getItem('tablealias'))
      .onSnapshot(doc => {
        doc.docs.forEach(docs => {
          console.log(docs.id, '_agent_list')
          agent_list.push(docs.id)
        })
        resolve(agent_list)
      })
  })
}


export const setRevoke = data => {
  var user_details = {}
  user_details[`settings.data_management.data_consent.revoke`] = data
  db
    .collection('user_' + localStorage.getItem('tablealias'))
    .doc(localStorage.getItem('agent_id'))
    .update(user_details)
}


export const getAppData = async () => {

  const userData = await getUserData(getCookie("APPYID"))
  var body=JSON.stringify({
    "method": "getFoodCourtAddedStatus",
    "emailId": userData.email
  });

  var appOptions=await fetch("https://snappy.appypie.com/webservices/FoodCourt.php", {
    method: "post",
    body: body,
    headers: { "Content-Type": "application/json" },
  })
  // .then(response => response.json())
  // .then(result => {return result.res})
  .catch(error => {return []});
  console.log(appOptions,typeof appOptions,"APPOPTIONS1")

  appOptions = await appOptions.json()
  console.log(appOptions.res,typeof appOptions,"APPOPTIONS")
  // let arr=[]
  // appOptions.res.map((element,index)=>{
  //   if(element.foodcourtStatus){
  //   arr.push({"value":element,"label":element.appName})
  //   }
  // })
  // appOptions=arr
  return appOptions.res
}


export const getPaymentData = async (botId) => {
  return new Promise((resolve, reject) => {
    //return resolve true
    db
  .collection("user_" + localStorage.getItem("tablealias"))
  .doc(localStorage.getItem("agent_id"))
  .collection("chatbot")
  .doc("dashboard").get().then((doc) => {
    if(doc.exists){
      const data = doc.data()[botId]
      console.log(data,"DATA OF PAID BOT",botId,data && data.payment,data.bot_template_category!=undefined)
      if(data && data.payment){
        if(data.bot_template_category){
          resolve({status:true,botExists:true})
        }
        else{
          
          resolve({status:true,botExists:false})
        }
      }
      else{
        resolve({status:false})
      }
    }
    else{
      resolve({status:false})
    }
  })
  .catch((err) => {
    console.log(err)
    reject(err)
  })
}) 
}



export const logoutCommonLogin = () => {
  let hostDomain = window.location.hostname;
  // if (hostDomain == "chatbotnew.appypie.com") {
  //   window.location.href =
  //   COMMON_LOGIN_URL + 
  //     '/logout?frompage=https://' +
  //     'chatbotlocal.appypie.com' +
  //     '/builder%2F&website=https://' +
  //     hostDomain +
  //     '/login'
  // } else {
  //   window.location.href =
  //   COMMON_LOGIN_URL +
  //     '/logout?frompage=https://' +
  //     'www.appypie.com/chatbot' +
  //     '/builder%2F&website=https://' +
  //     hostDomain +
  //     '/login'
  // }

  window.location.href =
  COMMON_LOGIN_URL + 
    '/logout?frompage=https://' +
   "www.appypie.com/chatbot" +
    '/builder%2F&website=https://' +
    hostDomain +
    '/login'



}



// SQL FOR HANDOFF LOG 
export const fetchHandoffLog=()=>{
  return new Promise((resolve, reject) => {
    var body=[`tablealias=${localStorage.getItem("tablealias")}&tabletype=handoff_logs&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-03-15"&graphtype=11`]
    fetch(API_URL + "/fetch", {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer "+getCookie("APPYID")
      },
      body:body,
      method: "POST"
    })
    .then(res=>res.json())
    .then((result) => {
      // console.log(result,"ZRESULT OF FETCH ")
      resolve(result.res[0])
    }).catch((err)=>{
      // console.log(err,"ERRR IN FETC")
      resolve([])
    })
  })

}

export const downloadJson=(data)=>{

    var download = function (content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";
      // console.log('content',content)
      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    };
    download(data, "download.json", "text/json;encoding:utf-8");
  
}

// SQL FOR ANSWER
export const fetchAnswerLog=()=>{
  return new Promise((resolve, reject) => {
    var body=[`tablealias=${localStorage.getItem("tablealias")}&tabletype=answerlog&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-03-15"&graphtype=12`]
    fetch(API_URL + "/fetch", {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer "+getCookie("APPYID")
      },
      body:body,
      method: "POST"
    })
    .then(res=>res.json())
    .then((result) => {
      // console.log(result,"ZRESULT OF FETCH ")
      resolve(result.res[0])
    }).catch((err)=>{
      // console.log(err,"ERRR IN FETC")
      resolve([])
    })
  })

}



//fetching extra inquiries of the bots
export const fetchExtraInquiries = (botId) => {
  return new Promise((resolve, reject) => {

  db
    .collection("user_" + localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .collection("chatbot")
    .doc(botId)
    .get().then((data) => {
      if (data.exists && data.data().hasOwnProperty("Extradata")) {
				console.log(data.data().Extradata, "extradata", typeof data.data().Extradata)
				var dbdata = data.data().Extradata
				console.log(dbdata, typeof dbdata)
				const csvdata = [['Entry Date', 'Name', 'Email', 'Phone', 'ChatsFlow']]
				const extradata = []
				for (var i in dbdata) {

					if (parseInt(new Date().getTime()) - i >= 2592000000) {
						var delRef = db.collection("user_" + localStorage.getItem('tablealias')).doc(localStorage.getItem("agent_id")).collection("chatbot").doc(botId)
						delRef.update({
							"Extradata": firebase.firestore.FieldValue.arrayRemove(i)
						});
						var ei = {};
						ei[`${botId}.extra_inquiries`] = firebase.firestore.FieldValue.increment(-1);
						db.collection("user_" + localStorage.getItem('tablealias')).doc(localStorage.getItem("agent_id")).collection("chatbot").doc('dashboard').update(ei)
						continue
					}

					var extrarr = {}
					var csvarr = []
					extrarr["Date"] = new Date(dbdata[i].create_on.seconds * 1000).toISOString().split('T', 1)[0]
					extrarr["Name"] = dbdata[i].Name
					extrarr["Email"] = dbdata[i].Email
					extrarr["Phone"] = dbdata[i].Phone
					extrarr["chatData"] = dbdata[i].chatData
					csvarr.push(new Date(dbdata[i].create_on.seconds * 1000).toISOString().split('T', 1)[0])
					csvarr.push(dbdata[i].Name)
					csvarr.push(dbdata[i].Email)
					csvarr.push(dbdata[i].Phone)
					csvarr.push(dbdata[i].chatData)
					extradata.push(extrarr)
					csvdata.push(csvarr)
				}
				

				
				resolve({ extradata, csvdata })
			}
      else {
        resolve(false)
      }
    })

  })
}


//call encrypted data
export const callEncryptedData = async (data) => {


  return new Promise((resolve , reject) => {
    const encryptDecryptData = async (type, d) => {
      return new Promise(async (resolve, reject) => {
        var apiUrl=API_URL+"/commonBillingencryption"
        var data = JSON.stringify({
          action: type,
          string: d,
        });
  
        var config = {
          method: "post",
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(async (response) => {
            //   console.log(response.data,'encryptdecrypt');
            if (type == "encrypt") {
              // console.log(response.data.string, "sssencrypt");
              resolve(await meterBillingFunction(response.data.string));
            } else {
              // console.log(response.data, "sssdecrypt");
              resolve(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    };
    const meterBillingFunction = async (dataStr) => {
      return new Promise(async (resolve, reject) => {
        // console.log(dataStr,"meterbillingscript")
        var data = dataStr;
        var config = {
          method: "post",
          url: COMMON_BILLING_URL+'/api',
          mode: "no-cors",
          headers: {
            "Content-Type": "text/plain",
          },
          data: data,
        };
        await axios(config)
          .then((res) => {
            // console.log(res.data, "apiapiresponse");
            resolve(encryptDecryptData("decrypt", res.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    };
  
  
    encryptDecryptData("encrypt" , data).then((output) => {
      resolve(output)
    })


     
     
  })
}

// export const installSlackInDB=(slackToken)=>{
//   return new Promise((resolve, reject) => {
//     db.collection("user_" + localStorage.getItem('tablealias')).doc(localStorage.getItem("agent_id")).update({
//       slack_token: slackToken,
//     }).then(()=>{
//       resolve(true)
//     })
//   })
// }

export const installSlackSetupInDB = (slackToken, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(slackToken, "slack_setup", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};





export const slackChannelApi = (data)=>{
  return new Promise(async (resolve, reject) => {

  let config = {
    method: 'get',
    url: API_URL + "/getSlackChannels",
    params: {
      access_token: data
    }
  };
  
  axios(config)
    .then((response) => {
      console.log(response, "allchannel");
      // setSlackChannels(response.data.channels);
      resolve(response.data.channels)
      // setMode("edit");
      // setIsLoading(false);
    })
    .catch((error) => {
      resolve([])
    });
  })
  
}

export const installSlackChannel = (data,addGlobal)=>{
  return new Promise(async (resolve, reject) => {
          db.collection('user_'+localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).update({slack_channel:data});

          setHandoffinDBConnect(data, "slack", addGlobal).then((doc) => {
      resolve(true);
    });
  });
}
export const uninstallSlack = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("slack_setup").then((doc) => {
      deleteIntegrationInDBConnect("slack").then((doc) => {
        removeGlobal("slack",0).then((doc) => {
          removeGlobal("slack_setup",0).then((doc) => {
        resolve(true);
          })
        })
        })
    });
  });
};



//set cookie for connect
const setCookieForConnect = (cookie) => {
  if(getCookie('connect.sid')&&getCookie('common_eml')&&getCookie('APPYID')){
  var data={}
  data["zoomCookie"]=`connect.sid=${getCookie('connect.sid')}; common_eml=${getCookie('common_eml')}; APPYID=${getCookie('APPYID')}; 79fefaf2564aabb19d44fe324844c86b=1;`
  db.collection('user_'+localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).update(data)
  .then(()=>{
    console.log("COOKIE SET")
  })
  .catch((err)=>{
    console.log(err,"COOKIE NOT SET")
  })}else{
    
    // localStorage.clear()
  }
}

export const setDataforTrialMail = ( ) =>{
  return new Promise(async (resolve, reject) => {



             const userData = await getUserData(getCookie("APPYID"))
                db
                .collection('New_Bot_list')
                .doc(new Date(new Date().setUTCHours(0,0,0,0)).getTime()+"").collection("BOTLIST").doc(localStorage.getItem("botId"))
                .set({
                  botId: localStorage.getItem("botId"),
                  cId: localStorage.getItem('tablealias'),
                  created_at: new Date().getTime(),
                  emailId: userData.email,
                  phone: userData.phone_number,
                  botName:sessionStorage.getItem("botName"),
                    botType:sessionStorage.getItem("isAIBot")?17: sessionStorage.getItem("botType"),
                    botPer:localStorage.getItem("answer_type")?localStorage.getItem("answer_type"):"",
                  isTrialReminder: false
                }).then(()=>resolve(true))
                .catch((err)=>resolve(false))
              })
}


//DEF - logging the activities of chatbot
export const trackData= (key, activityType , description , activityData)=>{

    return new Promise(async (resolve , reject) => {

      const userData = await getUserData(getCookie("APPYID"))

    if(!userData){
      resolve(false)
      return
    }

      //end point
      const ACTIVITY_LOG_URL = COMMON_LOGIN_API_URL + '/api/activity/activity-log'
      // const ACTIVITY_LOG_URL = 'https://commonlogin.pbodev.info/backend/api/activity/activity-log'
      
      //activity log json data
      var data={
        "email":userData.email?userData.email:"",
        "product_name":"chatbot",
        "description":description?description:"",
        "userAgent":window.navigator.userAgent,
        "ip":localStorage.getItem("ip"),
        "activityType":activityType,
        "log_key":key,//Chatbot_signup/Bot_selected/
        "data":activityData,//Multiple key value pairs other than email and product_name
        "userId":localStorage.getItem("commonlogin_id")?parseInt(localStorage.getItem("commonlogin_id")):"",
      }

      
      //encrypting the data
      encryptActivityLogData(data).then(res => {
        
        if(res && res.message.toLowerCase('success')){
          var requestOptions = {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",            
          },
            body: JSON.stringify({payload : res.string}),           
        };

        fetch(ACTIVITY_LOG_URL, requestOptions).then(res => {
            if(res && res.status === 200){
               //log success
              console.log('activity log success ')
            
              resolve(true)
            }
            else{

              //log failed
              console.log('activity log failed 2')
              resolve(false)
            }
        }).catch(err => {
          //log failed
          console.log('activity log failed')
          resolve(false)
        })
          
        }
        else{

          //encryptionn failed
          console.log('encryption falied 2')
          resolve(false)
        }
      }).catch(err => {

        //encryption failed
        console.log('encryption failed')
        resolve(false)
      })
      

    })



    //RESPONSE OF ACTIVITY LOG API
    //   {
    //     "status": 200,
    //     "status_msg": 200,
    //     "message": "successfully updated"
    // }

  
}

//DEF - encrypt activity log data
export const encryptActivityLogData = (data) => {

  return new Promise((resolve , reject) => {

  //end point
  // const ENCRYPTION_URL = API_URL + '/payment_encryption'
  const ENCRYPTION_URL = API_URL + '/commonBillingencryption'


  const payloadData = {
    action : "encrypt",
    string : data
  }

  axios.post(ENCRYPTION_URL , payloadData).then(res => {
      if(res && res.data){
        const encryptedData = res.data.string
        resolve({
          message : "Success",
          string : encryptedData
        })
      }
      else{
        reject({
          message : "Failed",
          err : "Something went wrong"
        })
      }
  }).catch(err => {
        reject({
          message : "Failed",
          err : err.message
        })
  })
  })

}


export const setApiKeyInDb = (data) => {
  
  return new Promise((resolve, reject) => {

    // var doc={}
    // doc[type]=key
    db
  .collection('Api_Key')
  .doc(localStorage.getItem("botId"))
  .set(data, { merge: true })
  .then(() => {
    console.log('API key set or updated successfully');
    resolve(true);
  })
  .catch(error => {
    console.error('Error setting API key:', error);
    resolve(false);
  });

})
}


//setting rag  data in the db
export const setRagDataInDb = (data) => {
  
  return new Promise((resolve, reject) => {

    
    db
  .collection('Api_Key')
  .doc(localStorage.getItem("botId"))
  .get().then(doc => {
    if(doc.exists){
      let ragData = doc.data().rag

      //if rag object is already present in db
      if(ragData){
        ragData = {
          ...ragData,
          ...data
        }

        db.collection('Api_Key').doc(localStorage.getItem("botId")).update({rag : ragData}).then(res => {
          resolve(true)
        }).catch(err => {
          resolve(false)
        })
      }

      //if rag object is not present in db
      else{
        
        db.collection('Api_Key').doc(localStorage.getItem("botId")).update({rag : data}).then(res => {
          resolve(true)
        }).catch(err => {
          resolve(false)
        })
      }
    }
    else{
      db
          .collection('Api_Key')
          .doc(localStorage.getItem("botId"))
          .set({rag : data})
          .then(() => {
           
            resolve(true);
          })
          .catch(error => {
            console.error('Error setting rag data:', error);
            resolve(false);
          });
    }
  })
  

})
}

//setting llm data in db
export const setLlmDataInDb = (data) => {
  
  return new Promise((resolve, reject) => {


    db  
      .collection('Api_Key')
      .doc(localStorage.getItem("botId")).get().then(doc => {
        if(doc.exists){
         
            if(doc.data().llmData){
                  const existingData = doc.data().llmData
                  const newData = data.llmData
                  const combinedData = {
                    ...existingData , 
                    ...newData
                  }

                  console.log(combinedData , ' combined data')
                  db  
                  .collection('Api_Key')
                  .doc(localStorage.getItem("botId"))
                  .update({
                    llmData : combinedData
                  })
                  .then(data => {
                    
                    console.log('llm data is updated')
                    resolve(true)
                  })
            }
            else{
              db  
              .collection('Api_Key')
              .doc(localStorage.getItem("botId"))
              .set(data)
              .then(data => {
                
                console.log('llm data is set for the first time')
                resolve(true)
              })
            }

          
        }
        else {
          db
          .collection('Api_Key')
          .doc(localStorage.getItem("botId"))
          .set(data)
          .then(() => {
            console.log('llm data added successfully');
            resolve(true);
          })
          .catch(error => {
            console.error('Error setting API key:', error);
            resolve(false);
          });
        }
      })
   
    })
}


//getting llm data from the db 
export const getLlmDataFromDb = (botId , nodeId) => {
  return new Promise((resolve, reject) => {
    db.collection("Api_Key").doc(botId).get().then(doc=>{
      if(doc.exists){


        console.log(doc.data() , '  doc.data')
        console.log(botId , '  botId')
        console.log(nodeId , '  nodeId')
        if(doc.data().llmData && doc.data().llmData[nodeId]){
          resolve(doc.data().llmData[nodeId])
        }
        else {
          resolve(false)
        }
      
      }else{
        resolve(false)
      }
    }).catch(err => {
      resolve(false)
    })
  })
}


//getting embedding data  from the db 
export const getRagDataFromDb = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("Api_Key").doc(botId).get().then(doc=>{
      if(doc.exists){


        console.log(doc.data() , '  doc.data')
        console.log(botId , '  botId')
       //returning ther rag data
       resolve(doc.data())
      
      }else{
        resolve(false)
      }
    }).catch(err => {
      resolve(false)
    })
  })
}

export const getDynamicBotData=(Id)=>{
  return new Promise((resolve, reject) => {
    db.collection("Bot_Flow_Dynamic/BotList/chatbot").doc(Id+"").get().then(doc=>{
      if(doc.exists){
      resolve(doc.data().button)
      }else{
        resolve(false)
      }
    })
  })
}


//converting the bot into the free is the current date exceeds the nextBillingDate
export const convertBotsIntoFree = async (all_bots) => {

 return new Promise((resolve, reject) => {

  let current_date = new Date().getTime();

  if(all_bots){
  const keysArray = Object.keys(all_bots);

  for (const key of keysArray) {
    if (all_bots[key].payment != undefined) {
      let nextBillingDate = new Date(
        all_bots[key].payment.nextBillingDate
      ).getTime();
      
      if (
        all_bots[key].payment.payment_status == "trial" ||
        all_bots[key].payment.payment_status == "trialWithoutCC"
      ) {
        if (current_date  >= nextBillingDate+24*60*60*1000) {
          let dashboard_bot_details = {};
          
          dashboard_bot_details[`${key}.payment.cancelType`] =
              "trial-cancelType";
            
          dashboard_bot_details[`${key}.payment.payment_status`] =
            "canceled";
          dashboard_bot_details[`${key}.payment.planName`] = "Free";
          dashboard_bot_details[`${key}.payment.planPeriod`] = "Monthly";
          dashboard_bot_details[`${key}.payment.cancelReason`] = "Dashboard Check";
          dashboard_bot_details[`${key}.handoffsSubscribed`] = 50;
          dashboard_bot_details[`${key}.extra_reminder_mail`] = 0;
          dashboard_bot_details[`${key}.warning_mail`] = 0;
          db
            .collection("user_" + localStorage.getItem("tablealias"))
            .doc(localStorage.getItem("agent_id"))
            .collection("chatbot")
            .doc("dashboard")
            .update(dashboard_bot_details)
            .then(() => {
              
            });
        }
      }else if (
          all_bots[key].payment.payment_status == "complete" 
        ) {

          const days=all_bots[key].payment.paymentMethod=="manual"?2:7
          if (current_date  >= nextBillingDate+24*days*60*60*1000) {
            let dashboard_bot_details = {};
            
            dashboard_bot_details[`${key}.payment.cancelType`] =
                "paid-cancelType";
              
            dashboard_bot_details[`${key}.payment.payment_status`] =
              "canceled";
            dashboard_bot_details[`${key}.payment.planName`] = "Free";
            dashboard_bot_details[`${key}.payment.planPeriod`] = "Monthly";
            dashboard_bot_details[`${key}.payment.cancelReason`] = "Dashboard Check";
            dashboard_bot_details[`${key}.handoffsSubscribed`] = 50;
            dashboard_bot_details[`${key}.extra_reminder_mail`] = 0;
            dashboard_bot_details[`${key}.warning_mail`] = 0;
            db
              .collection("user_" + localStorage.getItem("tablealias"))
              .doc(localStorage.getItem("agent_id"))
              .collection("chatbot")
              .doc("dashboard")
              .update(dashboard_bot_details)
              
          
        }
      }
    }
  }

  resolve(true)
}else{
  resolve(true)
}

 })

  
}


//updating new appointment
export const updateApptCount=(data)=>{
  return new Promise((resolve, reject) => {

    var body={}
    body[`appointment.count`]=data
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .update(body).then(result=>{
      console.log("UPDATED APPT COUNT")
      resolve(true)
    })
    .catch(error => {
      console.log('error', error)
      resolve(false)
    })
  })
}


//downloading json data in csv file


export function downloadJsonAsCsv(jsonData, csvFileName) {
  // Helper function to convert each value to an appropriate string
  function convertValue(value) {
      if (typeof value === 'object' && value !== null) {
          return JSON.stringify(value);  // Convert objects to JSON string
      }
      return String(value);  // Convert other types to string
  }

  // Convert JSON to CSV
  const csvData = Object.keys(jsonData[0]).join(',') + '\n' +
      jsonData.map(row => 
          Object.values(row).map(convertValue).join(',')
      ).join('\n');

  // Create a Blob with the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a download link
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvFileName;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
}

// // Example usage:
// const jsonData = [
//   { name: 'John', age: 30, city: 'New York' },
//   { name: 'Jane', age: 25, city: 'San Francisco' },
//   // Add more data as needed
// ];

// const csvFileName = 'data.csv';

// downloadJsonAsCsv(jsonData, csvFileName);



// export const getAppyEmailData = async () => {
//       return new Promise((resolve , reject) => {
//         console.log("getting appy_email data");
//         db.collection("Connect_List")
//           .doc(localStorage.getItem("tablealias"))
//           .collection("chatbot")
//           .doc(localStorage.getItem("botId"))
//           .collection("all_integrations")
//           .doc('appy_email')
//           .get().then(res => {
//             console.log(res , ' only res')
//             console.log(res.data() , ' res.data')
//             resolve(res.data())
//           }).catch(err => {
//             resolve(err)
//           })
          
//         })}


//deleting appy_email from the db 
export const deleteAppyEmailDataFromDb = () => {
  return new Promise((resolve , reject) => {
          console.log("deleting appy_email data");
          

          const collectionPath = "Connect_List";
          const tableAlias = localStorage.getItem("tablealias");
          const chatbotCollection = "chatbot";
          const botId = localStorage.getItem("botId");
          const integrationsCollection = "all_integrations";
          const documentIdToDelete = "your_document_id"; // Replace with the actual document ID you want to delete
          
          const docPath = `${collectionPath}/${tableAlias}/${chatbotCollection}/${botId}/${integrationsCollection}`;
          
          const docRef = db.doc(`${docPath}/appy_email`);
          
          // Delete the document
          docRef.delete()
            .then(() => {
              console.log("Document successfully deleted!");
              resolve(true)
            })
            .catch((error) => {
              console.error("Error deleting document: ", error);
              resolve(false)
            });
          
    })
}


export const getJwtData = async (token) => {
  return new Promise((resolve , reject) => {
   
    var raw = JSON.stringify({});

    var requestOptions = {
      method: 'POST',
      headers: {"Authorization": token},
      body: raw,
      redirect: 'follow'
    };
    // const TEMPURL = 'http://localhost:5000/chatbot-production-d6ea3/us-central1/twilioNew/decrypt'
    // const LIVURL = 'https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/twilioNew/decrypt'
    fetch(API_URL + '/twilioNew/decrypt', requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        resolve(result)
      })
      .catch(error => console.log('error', error));
  })
}

export const getJwtApptData = async (token) => {
  return new Promise((resolve , reject) => {
   
    var raw = JSON.stringify({});

    var requestOptions = {
      method: 'POST',
      headers: {"Authorization": token},
      body: raw,
      redirect: 'follow'
    };
    // const TEMPURL = 'http://localhost:5678/chatbot-production-d6ea3/us-central1/twilioNew/decryptAppt'
    // API_URL + '/twilioNew/decrypt'
    // const LIVURL = 'https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/twilioNew/decrypt'
    fetch(API_URL+"/twilioNew/decryptAppt", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        resolve(result.message)
      })
      .catch(error => console.log('error', error));
  })
}




//UTILITY FUNCTION
//DEF - CLEANS THE CROSS SCRIPTING XSS
export const cleanupString = (stringItem) =>  {
  var specialcharacters = stringItem.trim().replace(/[^\w\s]/gi, '')
return specialcharacters.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-"); 
//this.trim(); //this.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
}


//UTILITY FUNCTION
//DEF - removes the html tag and keeps the special tag from the string
export const removeHtmlTags = (str) => {
	if ((str===null) || (str===''))
	return "";
	else
	str = str.toString();
	return str.replace( /(<([^>]+)>)/ig, '');
}



//UTILITY FUNCTION
//DEF - checks whether the rag flow is correct or not
export const checkRagFlow = (template , dataSourceID) => {


  //position of rag nodes in the chatbot flow
  const indexCorrespondsToNodeWithoutCsv = {
    0: "data source",
    1: "splitter",
    2: "embedding",
    3: "vector",
    4: "llm",
};



   //getting the flow
    const flow = template.template_One

  
    var currentNode = dataSourceID
    

   for(var currentIndex = 0 ; currentIndex < 5 ; currentIndex++){
      console.log("currentNode " , currentNode , " currentnodedata " , flow[currentNode])

      const currentUI = flow[currentNode].UI
      if(currentUI.toLowerCase() === indexCorrespondsToNodeWithoutCsv[currentIndex]){
          currentNode = Number(flow[currentNode].next)
      }
      else{
        //rag nodes are not in sequence therefore returning false
        return false
      }
      
    }


    //all the rag nodes are in sequence
    return true

}


// //UTILITY FUNCTION
// //DEF - get node type data based on the node type id
export const getNodeTypeData = (nodeMenu , nodeTypeId) => {
      const result = nodeMenu.filter((node) => {
        if(node.id === nodeTypeId){
          return node
        }
      })
      if(result.length > 0){
        return result[0]
      }
      else{
        return null
      }
       


}

//DEF - setting google key in db
export const storeGoogleToken = async (token) => {


  return new Promise((resolve , reject) => {
    console.log(token , ' google token');
  // Reference to the collection "Api_key"
var apiKeyRef = db.collection("Api_key").doc(localStorage.getItem("botId"));

// Check if the document exists
apiKeyRef.get().then(function(doc) {
    if (doc.exists) {
        // Document exists, update the API key
        return apiKeyRef.update({
            googleToken: token
        });
    } else {
        // Document doesn't exist, set the API key
        return apiKeyRef.set({
            googleToken: token
        });
    }
}).then(function() {
    console.log("google token set  successful");
    resolve(true)
}).catch(function(error) {
    console.error("Error in setting google token ", error);
    reject(error.message)
});
  })
}

//DEF - getting the google token from the db
export const fetchGoogleToken = async (token) => {


  return new Promise((resolve , reject) => {
    console.log(token , ' google token');
  // Reference to the collection "Api_key"
var apiKeyRef = db.collection("Api_key").doc(localStorage.getItem("botId"));

// Check if the document exists
apiKeyRef.get().then(function(doc) {
    if (doc.exists) {
        // Document exists, update the API key
        const data = doc.data()
        console.log(data , ' data from the db');


        if(data.googleToken){
          console.log(data.googleToken , ' token from the db');
          resolve(data.googleToken)
        }
        else{
          console.log('no google token found');
          reject('no google token found')
        }

        
    } else {
      console.log('no token data found');
        reject('no data found')
        // Document doesn't exist, set the API key
        
    }
})
  })
}









